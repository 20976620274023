import React, {useState, useEffect} from 'react';
//import { showNotify } from '../../../services/actionsAppService.jsx';
import {useDispatch, useSelector} from 'react-redux';
import ImgProductDefault from '../../../assets/images/image-default-1.png';
import {
	setProduct as setProductInShoppingcart, changeQuantityProduct, changeQuantityPackages, 
	plusQuantityProduct, plusQuantityPackages, decreaseQuantityProduct
} from '../../../store/modules/shoppingcart/actions';

import './style.css';
import { NumericFormat } from 'react-number-format';

const BoxProductItem = props => {
	const dispatch = useDispatch();
	const productsShoppingcart = useSelector(state => state.shoppingcart.products);
	const [idInputOnChange, setIdInputOnChange] = useState('');

	const addProductToShoppingCart = (item) => {
		if(props?.type === "product"){
			dispatch(setProductInShoppingcart(item, 'product'));
		}else{
			dispatch(setProductInShoppingcart(item, 'wholesale'));
		}
	}

	const decreaseQuantityProductToShoppingCart = (idProduct) => {
		productsShoppingcart.forEach(function(el, i){
		    if(el?.dataProduct?.id === idProduct) {
		    	dispatch(decreaseQuantityProduct(i));
		    }
		});
	}

	const changeQuantityProductToShoppingCart = (e, idProduct, idInput) => {
		let value = e.target.value == ''  || e.target.value == '0' ? 0 : e.target.value;

		if(props?.type === "package-product"){
			productsShoppingcart.forEach(function(el, i){
			    if(el?.dataProduct?.id === idProduct) {
			        Promise.resolve(dispatch(changeQuantityPackages(i, value))).then((value) => {
			        	let element = document.getElementById(idInput);
			        	element.focus();
					});
			    }
			});
		}else{
			productsShoppingcart.forEach(function(el, i){
			    if(el?.dataProduct?.id === idProduct) {
			        Promise.resolve(dispatch(changeQuantityProduct(i, value))).then((value) => {
			        	let element = document.getElementById(idInput);
			        	element.focus();
					});
			    }
			});
		}
	}

	const plusQuantityProductToShoppingCart = (idProduct) => {
		if(props?.type === "package-product"){
			productsShoppingcart.forEach(function(el, i){
			    if(el?.dataProduct?.id === idProduct) {
			        dispatch(plusQuantityPackages(i));
			    }
			});
		}else{
			productsShoppingcart.forEach(function(el, i){
			    if(el?.dataProduct?.id === idProduct) {
			        dispatch(plusQuantityProduct(i));
			    }
			});
		}

		
	}

	const getQuantityProductToShoppingCart = (idProduct) => {
		let quantity = 0;
		productsShoppingcart.forEach(function(el, i){
			if (el?.dataProduct?.id === idProduct) {
				quantity = el?.quantity;
				return;
			}
		});
		return quantity;
		
	}

	const containProductInShoppingcart = (idProduct) => {
		let condition = false;
		productsShoppingcart.forEach(function(el, i){
		    if(el?.dataProduct?.id === idProduct)
		        condition = true;
		});
		return condition;
	}

	const RenderBoxByType = () => {
		if(props?.type === "product"){
			return (
				<div className="area-box-product-item">
					{props?.item?.product?.image !== null ?
						<img alt="" src={props?.item?.product?.image} />
						:
						<img alt="" src={ImgProductDefault} />
					}
					
					<label className="name-product">{props?.item?.product?.name}</label>
					<label className="price-product">{(props?.item?.price/100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
					{containProductInShoppingcart(props?.item?.id) === false ?
						<button className="btn-add-product" onClick={() => addProductToShoppingCart(props?.item)}>Adicionar</button>
						:
						<div>
							<button onClick={() => decreaseQuantityProductToShoppingCart(props?.item.id)}>-</button>
							<NumericFormat
								displayType="input"
								className="input-quantity-product"
								id={`input-item-${props?.item.id}`}
								value={getQuantityProductToShoppingCart(props?.item.id)}
								onBlur={(e) => changeQuantityProductToShoppingCart(e, props?.item.id, `input-item-${props?.item.id}`)}
							/>
							<button onClick={() => plusQuantityProductToShoppingCart(props?.item.id)}>+</button>
						</div>
					}
				</div>
			);
		}else{
			return (
				<div className="area-box-product-item">
					{props?.item?.store_product?.product?.image !== null ?
						<img alt="" src={props?.item?.store_product?.product?.image} />
						:
						<img alt="" src={ImgProductDefault} />
					}
					
					<label className="name-product">{props?.item?.name}</label>
					<label className="price-product">{(props?.item?.price/100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
					{containProductInShoppingcart(props?.item?.id) === false ?
						<button className="btn-add-product" onClick={() => addProductToShoppingCart(props?.item)}>Adicionar</button>
						:
						<div>
							<button onClick={() => decreaseQuantityProductToShoppingCart(props?.item.id)}>-</button>
							<NumericFormat
								displayType="input"
								className="input-quantity-product"
								id={`input-item-${props?.item.id}`}
								value={getQuantityProductToShoppingCart(props?.item.id)}
								onBlur={(e) => changeQuantityProductToShoppingCart(e, props?.item.id, `input-item-${props?.item.id}`)}
							/>
							<button onClick={() => plusQuantityProductToShoppingCart(props?.item.id)}>+</button>
						</div>
					}
				</div>
			);
		}
	}

	return (
		<RenderBoxByType />
	);
};

export default BoxProductItem;