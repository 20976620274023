import React, {useState, useEffect} from 'react';
import apiService from '../../../../services/api.js';
import { GoPackage } from 'react-icons/go';
import ModalWholesales from '../../../../components/PanelToten/ModalWholesales/index.jsx';
import BoxProductItem from '../../../../components/PanelToten/BoxProductItem/index.jsx';
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import {useDispatch, useSelector} from 'react-redux';
import {setProducts} from '../../../../store/modules/products/actions';
import './style.css';

const ListProducts = () => {
	const dispatch = useDispatch();
	const api = apiService.get();
	let idStore = JSON.parse(localStorage.getItem('toten')).store_id;
	const storeProducts = useSelector(state => state.products.products);
	

	const [showModalWholesales, setShowModalWholesales] = useState(false);
	const [storeProductIdSelected, setStoreProductIdSelected] = useState('');

	

	const initProducts = () => {
		api.get(`api/store-product/by-store/${idStore}`)
		.then(resp => {
			const data = resp.data.data.storeProducts;
			console.log('store products data');
			console.log(data);
			dispatch(setProducts(data));
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			//setErrors(error.message)
			verifyError(error);
		})
	}

	

	const openModalWholesales = (storeProductId) => {
		setStoreProductIdSelected(storeProductId);
		setShowModalWholesales(true);
	}

	
	useEffect(() => {
		if(storeProducts?.length === 0){
			initProducts();
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<ul className="list-products-searched">
				{storeProducts?.map((item, index) => (
					<li key={index}>
						{/* <button className="btn-modal-wholesales" onClick={(e) => openModalWholesales(item?.id)}><GoPackage size="20" /></button> */}
						<BoxProductItem item={item} type="product"></BoxProductItem>
					</li>
				))}
			</ul>

			<ModalWholesales productStoreId={storeProductIdSelected} show={showModalWholesales} setShow={setShowModalWholesales} />
		</>
	);
};

export default ListProducts;