import React, {useEffect, useState} from 'react';
import { ImCross } from 'react-icons/im';
import BoxProductItem from '../BoxProductItem/index.jsx';
import apiService from '../../../services/api.js';
import { showNotify, verifyError } from '../../../services/actionsAppService.jsx';
import './style.css';


const ModalWholesales = props => {
	const [productsWholesales, setProductsWholesales] = useState([]);
	const api = apiService.get();
	//let idStore = JSON.parse(localStorage.getItem('toten')).store_id;

    const closeModal = () => {
    	props.setShow(false);
    	document.getElementById("modal-wholesales").style.display = "none";
    }

    const initProductsWholesales = (productStoreId) => {
    	api.get(`api/package-product/by-store/${productStoreId}`)
		.then(resp => {
			const data = resp.data.data.packageProducts;
			setProductsWholesales(data);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			//setErrors(error.message)
			verifyError(error);
		})
    }

	useEffect(() => {
		if(props?.show){
			initProductsWholesales(props?.productStoreId);
    		document.getElementById("modal-wholesales").style.display = "block";
		}
    }, [props?.show]);/* eslint-disable-line */

	return (
		<div className="area-modal-wholesales" id="modal-wholesales">
			<button type="button" className="btn-close-modal-wholesales" onClick={closeModal}><ImCross size="12" /></button>
			
			<h4 className="title-modal-wholesales">Atacado</h4>

			<ul className="list-products-wholesales">
				{productsWholesales?.map((item, index) => (
					<li key={index}>
						<BoxProductItem item={item} type="package-product"></BoxProductItem>
					</li>
				))}
			</ul>
		</div>
	);
};

export default ModalWholesales;