import React, {useState, useEffect, useContext} from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BiPencil, BiTrash, BiShowAlt} from "react-icons/bi";
import { TbShoppingCartDiscount } from "react-icons/tb";
import Header from '../Header/index.jsx';
import apiService from '../../../../../services/api.js';
import Loading from '../../../../../components/Loading/index.jsx';
import Pagination from '../../../../../components/Pagination/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../../services/actionsAppService.jsx';
import Context from "../../../../../Context";
import { NumericFormat } from 'react-number-format';


const List = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('store-product');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('store-product');
	const [storeProducts, setStoreProducts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(1);
	const [qtdPages, setQtdPages] = useState(1);
	const api = apiService.get()
	let valuePrev = localStorage.getItem('searchValuePrev');

	const {
	    params: { column, value, storeId }
	} = match;

	const [storeProductSelected, setStoreProductSelected] = useState({});
	const [intervalsDiscounts, setIntervalsDiscounts] = useState([]);
	const [indexIntervalsDiscountsEditSelected, setIndexIntervalsDiscountsEditSelected] = useState(null);
	const [modalIntervalDiscountShow, setModalIntervalDiscountShow] = useState(false);
	const [modalRegisterEditIntervalDiscountShow, setModalRegisterEditIntervalDiscountShow] = useState(false);
	
	
	const onHideModalIntervalDiscount = () => {
		setModalIntervalDiscountShow(false);
	}

	const onHideModalRegisterEditIntervalDiscount = () => {
		setModalRegisterEditIntervalDiscountShow(false);
		setModalIntervalDiscountShow(true);
	}

	

	const list = () => {
		setLoading(true);
		let queryApi = '';
		if(typeof column !== "undefined" && typeof value !== "undefined"){
			if(valuePrev !== value){
				setOffset(1)
				localStorage.setItem('searchValuePrev', value);
			}
			queryApi = `api/${endpoint}/by-store/${storeId}/search/${column}/${value}?page=${offset}`;
		}else{
			queryApi = `api/${endpoint}/by-store/${storeId}?page=${offset}`;
		}
		
		api.get(queryApi)
		.then(resp => {
			setStoreProducts(resp.data.data.storeProducts);
			setQtdPages(resp.data.data.pagination.pages);
			console.log(resp.data.data.storeProducts);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}
	
	const deleteItem = (id, e) => {
		setLoading(true);
		api.delete(`api/${endpoint}/${id}`)
		.then(resp => {
			showNotify('success', 'Produto removido com sucesso!');
			list();
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	const listIntervalDiscount = (storeProduct) => {
		setStoreProductSelected(storeProduct);
		setIntervalsDiscounts(storeProduct?.interval_discounts);
		setModalIntervalDiscountShow(true);
	}

	const addDiscountAutomatically = () => {
		let objectReference = intervalsDiscounts[intervalsDiscounts.length - 1];
		let diference = objectReference.max_quantity_products - objectReference.min_quantity_products;

		setIntervalsDiscounts([...intervalsDiscounts, {
			discount_value: parseFloat(objectReference.discount_value * 2),
			min_quantity_products: parseFloat(objectReference.max_quantity_products + 1),
			max_quantity_products: parseFloat(objectReference.max_quantity_products + diference)
		}]);
	}


	const editDiscount = (index) => {
		setIndexIntervalsDiscountsEditSelected(index);
		setModalRegisterEditIntervalDiscountShow(true);
		setModalIntervalDiscountShow(false);
	}

	const registerDiscount = () => {
		setModalRegisterEditIntervalDiscountShow(true);
		setModalIntervalDiscountShow(false);
	}

	const addOrChangeDiscountOfSubmit = (event) => {
		if(indexIntervalsDiscountsEditSelected == null){
			setIntervalsDiscounts([...intervalsDiscounts, {
				discount_value: parseFloat(event.target.discount.value)/100,
				min_quantity_products: parseFloat(event.target.min_quantity_product.value),
				max_quantity_products: parseFloat(event.target.max_quantity_product.value),
			}]);
			showNotify('success', 'Desconto adicionado a lista, salve antes de sair!');
		}else{
			let listIntervalDiscountTemp = intervalsDiscounts;
			listIntervalDiscountTemp[indexIntervalsDiscountsEditSelected].discount_value = parseFloat(event.target.discount.value)/100;
			listIntervalDiscountTemp[indexIntervalsDiscountsEditSelected].min_quantity_products = parseFloat(event.target.min_quantity_product.value);
			listIntervalDiscountTemp[indexIntervalsDiscountsEditSelected].max_quantity_products = parseFloat(event.target.max_quantity_product.value);
			setIntervalsDiscounts(listIntervalDiscountTemp);
			setIndexIntervalsDiscountsEditSelected(null);
			showNotify('success', 'Desconto atualizado, salve antes de sair!');
		}
		setModalRegisterEditIntervalDiscountShow(false);
		setModalIntervalDiscountShow(true);
		event.preventDefault();
	}

	const removeDiscount = (index) => {
		setIntervalsDiscounts([...intervalsDiscounts.filter(item => intervalsDiscounts.indexOf(item) !== index)]);		
	}

	const saveDiscounts = () => {
		let data = {
			discounts: []
		};

		intervalsDiscounts.forEach(function(item, index){
			data.discounts.push({
				discount_value: item.discount_value,
            	min_quantity_products: item.min_quantity_products,
            	max_quantity_products: item.max_quantity_products
			});
		});

		setLoading(true);
		
		api.post(`api/${endpoint}/save-discounts/${storeProductSelected?.id}`, data)
		.then(resp => {
			setModalRegisterEditIntervalDiscountShow(false);
			setIndexIntervalsDiscountsEditSelected(null);
			list();
			showNotify('success', 'Descontos do produto foi salvo com sucesso');
			setLoading(false);
			
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}
	

	useEffect(() => {
		list()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [column, value, offset])


	return (
		<>
			<div className="area-crud">

				<Header storeId={storeId}></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-view`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Lista de produtos da loja</h5>

					{ (loading) ? (<Loading />) : (

						(storeProducts?.length === 0) ? (
		                    <label>Nenhum registro encontrado...</label>
		                ) : (
		                	<div>
			                    <Table striped bordered hover>
									<thead>
										<tr>
											<th style={configDefault?.theme?.prymaryBackground}>Produto</th>
											<th style={configDefault?.theme?.prymaryBackground}>Código EAN</th>
											<th style={configDefault?.theme?.prymaryBackground}>Preço</th>
											<th style={configDefault?.theme?.prymaryBackground}>Promoção</th>
											<th style={configDefault?.theme?.prymaryBackground}>Promoção disponível</th>
											<th style={configDefault?.theme?.prymaryBackground}>Quantidade</th>
											<th style={configDefault?.theme?.prymaryBackground}>Unidade de medida</th>
											<th style={configDefault?.theme?.prymaryBackground}>Ações</th>
										</tr>
									</thead>
									<tbody>
										{storeProducts?.map((item) => (
											<tr key={item.id}>
												<td>{item?.product?.name}</td>
												<td>{item?.product?.code_ean}</td>
												<td>{(item.price / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
												<td>{(item.promotion_price / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
												<td>{item.has_promotion ? 'Sim' : 'Não'}</td>
												<td>{item.quantity}</td>
												<td>{item?.product?.unit_measurement?.sigle || '-'}</td>
												<td>
													<Can resource={roleEndpoint} authority={`${roleEndpoint}-view`}>
														<Link to={`/dashboard/${endpoint}/${storeId}/view/${item.id}`} className="btn btn-dark" style={configDefault?.theme?.prymaryBackground}>
															<BiShowAlt />
														</Link>
													</Can>
													<Can resource={roleEndpoint} authority={`${roleEndpoint}-update`}>
														<Link to={`/dashboard/${endpoint}/${storeId}/edit/${item.id}`} className="btn btn-dark" style={configDefault?.theme?.prymaryBackground}>
															<BiPencil />
														</Link>
													</Can>
													<Can resource={roleEndpoint} authority={`${roleEndpoint}-delete`}>
														<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={(e) => {if (window.confirm('Você realmente deseja excluir este registro?')) deleteItem(item.id, e)}}>
															<BiTrash />
														</Button>
													</Can>
													<Can resource="interval-discount" authority={`interval-discount-view`}>
														<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={(e) => {listIntervalDiscount(item)}}>
															<TbShoppingCartDiscount />
														</Button>
													</Can>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
								<Pagination
									qtdPages={qtdPages}
									offset={offset}
									setOffset={setOffset}
								/>

							</div>
		                )
		            )}
	            </Can>
			</div>

			<Modal
                show={modalIntervalDiscountShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
					
					<h5 className="mb-2">Lista de descontos</h5>
					<h6 className="mb-3">Produto: {storeProductSelected?.product?.name}</h6>

					{ (loading) ? (<Loading />) : (

						(intervalsDiscounts?.length === 0) ? (
		                    <label>Nenhum desconto inserido!</label>
		                ) : (
		                	<div>
			                    <Table striped bordered hover>
									<tbody>
										{intervalsDiscounts?.map((item, index) => (
											<tr key={item.id}>
												<td>
													<label>de <b>{(item?.min_quantity_products).toFixed(2)}</b> a <b>{(item?.max_quantity_products).toFixed(2)}</b> = <b>{(item?.discount_value * 100).toFixed(2)}%</b></label>
												</td>
												<td>
													<Button size="sm" style={configDefault?.theme?.prymaryBackground} onClick={(e) => editDiscount(index)}><BiPencil /></Button>
													<Button size="sm" className="ms-1" style={configDefault?.theme?.prymaryBackground} onClick={(e) => removeDiscount(index)}><BiTrash /></Button>
												</td>

											</tr>
										))}
									</tbody>
								</Table>
								
								
							</div>

		                )
		            )}
                </Modal.Body>

                <Modal.Footer>
					<Button className="ms-1" variant="success" onClick={saveDiscounts}>Salvar</Button>
					<Button style={configDefault?.theme?.prymaryBackground} onClick={addDiscountAutomatically}>Auto</Button>
					<Button className="ms-1" style={configDefault?.theme?.prymaryBackground} onClick={registerDiscount}>Registra</Button>
                    <Button style={configDefault?.theme?.prymaryBackground} onClick={onHideModalIntervalDiscount}>Fechar</Button>
                </Modal.Footer>
            </Modal>




            <Modal
                show={modalRegisterEditIntervalDiscountShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
					
					<h5 className="mb-2">Registro de desconto</h5>
					<h6 className="mb-3">Produto: {storeProductSelected?.product?.name}</h6>

					<Can 
						resource={roleEndpoint}
						authority={`${roleEndpoint}-create`}
						otherwiseComponent={<label>Sem acesso!</label>}
					>

						{ (loading) ? 
							<Loading /> 
							:
							<Form onSubmit={addOrChangeDiscountOfSubmit} className="form-register" id="form-register">
								{indexIntervalsDiscountsEditSelected == null ? 
								<Row className="mb-3">
									<Col sm="12">
										<Form.Group>
										    <label htmlFor="min_quantity_product" className="form-label">Quantidade minima:<span className="req-span">*</span></label>
										    <NumericFormat displayType="input" name="min_quantity_product" className="form-control" id="min_quantity_product"  />
										</Form.Group>
									</Col>

									<Col sm="12">
										<Form.Group>
										    <label htmlFor="max_quantity_product" className="form-label">Quantidade maxima:<span className="req-span">*</span></label>
										    <NumericFormat displayType="input" name="max_quantity_product" className="form-control" id="max_quantity_product"  />
										</Form.Group>
									</Col>

									<Col sm="12">
										<Form.Group>
										    <label htmlFor="discount" className="form-label">Desconto em porcentagem:<span className="req-span">*</span></label>
										    <NumericFormat displayType="input" name="discount" className="form-control" id="discount"  />
										</Form.Group>
									</Col>
								</Row>
								
								:

								<Row className="mb-3">
									<Col sm="12">
										<Form.Group>
										    <label htmlFor="min_quantity_product" className="form-label">Quantidade minima  {indexIntervalsDiscountsEditSelected}:<span className="req-span">*</span></label>
										    <NumericFormat displayType="input" name="min_quantity_product" className="form-control" id="min_quantity_product" value={parseFloat(intervalsDiscounts[indexIntervalsDiscountsEditSelected]?.min_quantity_products)} />
										</Form.Group>
									</Col>

									<Col sm="12">
										<Form.Group>
										    <label htmlFor="max_quantity_product" className="form-label">Quantidade maxima:<span className="req-span">*</span></label>
										    <NumericFormat displayType="input" name="max_quantity_product" className="form-control" id="max_quantity_product" value={parseFloat(intervalsDiscounts[indexIntervalsDiscountsEditSelected]?.max_quantity_products)} />
										</Form.Group>
									</Col>

									<Col sm="12">
										<Form.Group>
										    <label htmlFor="discount" className="form-label">Desconto em porcentagem:<span className="req-span">*</span></label>
										    <NumericFormat displayType="input" name="discount" className="form-control" id="discount" value={parseFloat(intervalsDiscounts[indexIntervalsDiscountsEditSelected]?.discount_value)*100} />
										</Form.Group>
									</Col>
								</Row>
								}



							    <Row className="mb-3">
							    	<Col>
							    		<Button type="submit" className="btn" style={configDefault?.theme?.prymaryBackground}>Salvar</Button>
							    	</Col>
							    </Row>
							    
							</Form>
						}
					</Can>

					
                </Modal.Body>

                <Modal.Footer>
                    <Button style={configDefault?.theme?.prymaryBackground} onClick={onHideModalRegisterEditIntervalDiscount}>Fechar</Button>
                </Modal.Footer>
            </Modal>
		</>
	);
};

export default List;