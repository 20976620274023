import React, { useState, useEffect, useCallback } from 'react';

// Debounce function
const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

const BarcodeInput = ({ onEanChange, resetInputEan }) => {
  const [ean, setEan] = useState('');

  // Debounced version of the onEanChange function
  const debouncedOnEanChange = useCallback(debounce(onEanChange, 700), [onEanChange]);

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      debouncedOnEanChange(ean);
      setEan('');
    } else {
      setEan((prevEan) => prevEan + e.key);
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [ean]);

  useEffect(() => {
    if (resetInputEan) {
      setEan('');
      window.removeEventListener('keyup', handleKeyUp);
    }
  }, [resetInputEan]);

  return (
    <div style={{display: "none"}}>
      <input
        id="eanInput"
        type="text"
        style={{width: '250px'}}
        value={ean}
        onChange={() => {}}
      />
    </div>
  );
};

export default BarcodeInput;
