import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../../services/api.js';
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Loading from '../../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../../services/actionsAppService.jsx';
import Context from "../../../../../Context";
import { NumericFormat } from 'react-number-format';

const RegisterEdit = props => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('store-proposal');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('store-proposal');

	const { solicitationId } = useParams();

	const api = apiService.get();
	const history = useHistory();
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setValue, getValues } = useForm();
	const [stores, setStores] = useState([]);
	const [solicitation, setSolicitation] = useState({});
	const [storeId, setStoreId] = useState('');

	const [productsSelected, setProductsSelected] = useState([]);
	const [products, setProducts] = useState([]);
	
	const onSubmit = data => {
		if(solicitationId !== undefined){
			data.solicitation_id = solicitationId;
			data.ship_cost = parseFloat(getValues('ship_cost').toString().replace(',', '.')) * 100;
			create(data);
		}
	}

	const create = data => {
		setLoading(true);
		data.products = productsSelected;
		console.log(data);

		api.post(`api/${endpoint}`, data)
		.then(resp => {
			showNotify('success', 'Proposta registrada com sucesso!');
			setLoading(false);
			history.push(`/dashboard/solicitation/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const initStores = () => {
		api.get('api/store/all')
		.then(resp => {
			setStores(resp.data.data.stores);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const initSolicitation = () => {
		setLoading(true);
	    api.get(`api/solicitation/${solicitationId}`)
		.then(resp => {
			let data = resp.data.data.solicitation;

			data.solicitation_products.forEach(function(item, index){
				item.product_object = JSON.parse(item.product_object);
			});

			console.log(data);

			setSolicitation(data);

			

			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}

	const onChangeProducts = (e) => {
		let nameProduct = e.target.value;
		if(storeId == '' || storeId == null){
			showNotify('error', 'Selecione o id da loja!');
		}else{
			if(nameProduct.length >= 3){
				api.get(`api/store-product/products-by-store/${storeId}/${nameProduct}`)
				.then(resp => {
					const data = resp.data.data.productsByStore.data;
					setProducts(data);
					setLoading(false);
				})
				.catch(error =>{
					showNotify('error', 'Ops, ocorreu algum erro!');
					setErrors(error.message)
					verifyError(error);
				})
			}
		}
	}

	const onChangeStore = (e) => {
		setStoreId(e.target.value);
	}

	const selectProduct = (product) => {
		if(productsSelected.findIndex(item => item.id === product.id) < 0){
			setProductsSelected([...productsSelected, {
				store_product_id: product?.store_product_id,
				name: product?.name,
				quantity: 1,
				price: 0,
				unit_measurement: product?.unit_measurement?.sigle
			}]);
		}
	}

	const removeProduct = (productSelected) => {
		setProductsSelected([...productsSelected?.filter(item => item.id !== productSelected.id)]);
	}

	const onChangeQuantityProducts = (e, index) => {
		let array = productsSelected;
		array[index].quantity = e.target.value;
		setProductsSelected(array);
	}

	const onChangePriceProducts = (e, index) => {
		let array = productsSelected;
		array[index].price = parseFloat(e.target.value.replace(',', '.')) * 100;
		setProductsSelected(array);
	}
	
	useEffect(() => {
		initStores();
		initSolicitation();
		document.getElementById('form-register').reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [solicitationId])

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-create`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de registro</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onSubmit)} className="form-register" id="form-register">
						
						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="store_id" className="form-label">Loja:</label>
								    <select className="form-select" id="store_id" name="store_id" {...register("store_id")} onChange={(e) => onChangeStore(e)}>
								    	<option value="">Selecionar</option>
							            {stores?.map((store, index) => (
								    		<option value={store?.id} key={index}>{store?.name}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.store_id ? errors.store_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="name-product" className="form-label">Buscar produto:</label>
								    <input type="text" name="name_product" className="form-control" disabled={storeId == ''} id="name-product" onChange={(e) => onChangeProducts(e)} />
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
									<label htmlFor="ship-cost" className="form-label">Preço do frete:</label>
									<NumericFormat
										disabled={storeId == ''}
										value={getValues('ship_cost')}
										name="ship_cost"
										id="ship_cost"
										placeholder="preço"
										className="form-control"
									 	decimalScale={2}
									  	decimalSeparator=","
									  	fixedDecimalScale
									  	thousandSeparator="."
									  	onChange={(e) => setValue("ship_cost", e.target.value)}
									/>
								</Form.Group>
							</Col>
						</Row>


						<Row className="mb-3">
							<Col sm="4">
								{products.length > 0 ?
									<>
										<label className="form-label">Resultado da busca:</label>
										<ul className="list-group">
											{products?.map((product, index) => (
												<li className="list-group-item d-flex justify-content-between align-items-center">
													{product?.name} | {product?.unit_measurement?.sigle || '-'}
													<Button type="button" className="btn-sm" onClick={() => selectProduct(product)} style={configDefault?.theme?.prymaryBackground}>+</Button>
												</li>
											))}
										</ul>
									</>
									:
									<></>
								}
							</Col>

							<Col sm="4">
								{productsSelected.length > 0 ?
									<>
										<label className="form-label">Produtos selecionados:</label>

										<ul className="list-group">
											{productsSelected?.map((productSelected, index) => (
												<li className="list-group-item d-flex justify-content-between align-items-center">
													<Col sm="4">
														<label>{productSelected?.name} | {productSelected?.unit_measurement || '-'}</label>
													</Col>

													<Col sm="3">
														<input type="number" placeholder="quantidade" className="form-control px-2 py-0" onChange={(e) => onChangeQuantityProducts(e, index)} />
													</Col>

													<Col sm="3">
														<NumericFormat
															value={getValues('price')}
															name="price"
															id="price"
															placeholder="preço"
															className="form-control px-2 py-0"
														 	decimalScale={2}
														  	decimalSeparator=","
														  	fixedDecimalScale
														  	thousandSeparator="."
														  	onChange={(e) => onChangePriceProducts(e, index)}
														/>
													</Col>

													<Button type="button" className="btn-sm" onClick={() => removeProduct(productSelected)} style={configDefault?.theme?.prymaryBackground}>-</Button>
												</li>
											))}
										</ul>
									</>
									:
									<></>
								}

							</Col>

							<Col sm="4">
								<label className="form-label">Produtos da solicitação:</label>

								<ul className="list-group">
									{Object.keys(solicitation).length !== 0 ?
										<>
											{solicitation?.solicitation_products.map((item, index) => (
												<li className="list-group-item d-flex justify-content-between align-items-center">
													
													<label>{item?.product_object?.name} | {item?.product_object?.unit_measurement_sigle || '-'}</label>
													<span class="badge bg-primary rounded-pill">{item?.quantity}</span>
												</li>
											))}
										</>
										:
										<></>
									}
								</ul>
							</Col>


						</Row>


					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn" style={configDefault?.theme?.prymaryBackground}>Criar proposta</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default RegisterEdit;