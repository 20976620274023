import React, {useState, useEffect, useContext} from 'react';
import { BiSearch } from "react-icons/bi";
import { InputGroup, Modal, FormControl, Form, Button, Row, Col } from 'react-bootstrap';
import Context from "../../Context";
import './style.css';
import apiService from '../../services/api.js';
import { showNotify, verifyError } from '../../services/actionsAppService.jsx';
import { Can } from "react-access-level";
import Pagination from '../Pagination/index.jsx';
import Loading from '../Loading/index.jsx';

const SelectByType = (props) => {
	const [configDefault] = useContext(Context);
	const [errors, setErrors] = useState('');

	const [modalShow, setModalShow] = useState(false);
	const [offset, setOffset] = useState(1);
	const [qtdPages, setQtdPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const api = apiService.get();
	const [itemsCrud, setItemsCrud] = useState([]);
	const [inputSelected, setInputSelected] = useState({});
	const [modalRegisterTypeShow, setModalRegisterTypeShow] = useState(false);
	const [valueTypeRegister, setValueTypeRegister] = useState('');

	const onHideModalRegisterType = () => {
		setModalRegisterTypeShow(false);
		setModalShow(true);
	}
	const onHideModal = () => {setModalShow(false);}
	const onShowModalRegisterType = (e) => {
		setModalShow(false);
		setModalRegisterTypeShow(true);
	}
	const onShowModal = (e) => {
		setModalShow(true);
		setModalRegisterTypeShow(false);
	}

	const initItemsType = () => {
        setLoading(true);
        let column = 'name';
        let queryApi = '';
		if(column !== '' && value !== ''){
			queryApi = `api/${props.crud}/search/${column}/${value}?page=${offset}`;
		}else{
			queryApi = `api/${props.crud}?page=${offset}`;
		}

        api.get(queryApi)
        .then(resp => {
        	//alteracao da estrutura da requisicao
        	let texto = JSON.stringify(resp.data.data);
        	let inicioDaExtracao = texto.indexOf(":")+1;
        	let fimDaExtracao = texto.indexOf("pagination")-2;
        	texto = texto.slice(inicioDaExtracao, fimDaExtracao);
        	let data = JSON.parse(texto);

            setItemsCrud(data);
            setQtdPages(resp.data.data.pagination.pages);
            setLoading(false);
        })
        .catch(error =>{
            showNotify('error', 'Ops, ocorreu algum erro!');
            setLoading(false);
            verifyError(error);
        })
    }

    const submitType = (event) => {
		setLoading(true);
		event.preventDefault();
		let data = {
			name: valueTypeRegister
		}
		api.post(`api/${props.crud}`, data)
		.then(resp => {
			showNotify('success', 'Registro realizado com sucesso!');
			setValueTypeRegister('');
			onShowModal();
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors);
			setLoading(false);
			verifyError(error);
		})
	}

    const changeValueInputSelect = (ev, item) => {
    	if(item === null)
        	props.setInputSelected({});
        else
        	props.setInputSelected(item);
    }

    const changeValueTypeRegister = (ev) => {
        setValueTypeRegister(ev.target.value);
    }

    const changeValue = (ev) => {
        setValue(ev.target.value.trim());
    }

	const onSubmit = (e) => {
		initItemsType();
    }

	useEffect(() => {
		initItemsType();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.crud, modalShow, props.modalRegisterShow, offset])

	useEffect(() => {
		setInputSelected(props.inputSelected);
	}, [props.inputSelected])

	return (
		<>
			<div className="select-by-crud">
				<div className="area-value">
					<span>{inputSelected?.name || 'Selecionar'}</span>
					<div>
						<button type="button"><BiSearch onClick={(e) => onShowModal(e)} /></button>
					</div>
				</div>
			</div>

			<Modal
                show={modalShow}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                	<h4 className="mb-4">Selecionar {props?.labelSelect}</h4>
                	<Can 
						resource={`type-crud`}
						authority={`type-crud-view`}
						otherwiseComponent={<label>Sem acesso!</label>}
					>
                		<div className="actions-search-select-crud">
	                        <InputGroup className="input-search">
	                            <FormControl placeholder="Buscar" onChange={(ev) => changeValue(ev)} id="input-search-into-crud" value={value} />
	                            <Button onClick={(e) => onSubmit(e)} className="btn btn-dark" type="button" style={configDefault?.theme?.prymaryBackground}><BiSearch /></Button>
	                        </InputGroup>
                        </div>
	                	
	                    <ul className="list-items-select-crud">
							{ (loading) ? (<Loading />) : (

								(itemsCrud?.length === 0) ? (
				                    <label>Nenhum registro encontrado...</label>
				                ) : (
				                	<>
					                	<div className="mt-4 mb-4">
											{itemsCrud?.map((item) => (
												<li key={item.id}>
													<div class="form-check">
														<input className="form-check-input" onChange={(ev) => changeValueInputSelect(ev, item)} type="radio" name="flexRadioDefault" id={item.id} checked={props?.inputSelected?.id === item.id ? true : false} />
														<label className="form-check-label" for={item.id}>
													    	{item.name}
														</label>
													</div>
												</li>
											))}
										</div>

										<Pagination
											qtdPages={qtdPages}
											offset={offset}
											setOffset={setOffset}
										/>
									</>
				                )
				            )}
	                    </ul>

					</Can>
                </Modal.Body>

                <Modal.Footer>
                	<Button style={configDefault?.theme?.prymaryBackground} onClick={(e) => onShowModalRegisterType(e)}>Novo Registro</Button>
                    <Button style={configDefault?.theme?.prymaryBackground} onClick={onHideModal}>Selecionar</Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={modalRegisterTypeShow}
            >
                <Modal.Body>
                	<h4 className="mb-3">Registrar {props?.labelSelect}</h4>
	        		<Row className="mb-3">
						<Col sm="12">
							<Form.Group>
							    <label htmlFor="name" className="form-label">Nome:<span className="req-span">*</span></label>
							    <input type="text" name="name_type" className="form-control" id="name" value={valueTypeRegister} onChange={(ev) => changeValueTypeRegister(ev)} />
							    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
							</Form.Group>
						</Col>
					</Row>
                </Modal.Body>

                <Modal.Footer>
                	<Button type="button" variant="primary" onClick={(e) => submitType(e)} style={configDefault?.theme?.prymaryBackground}>Salvar</Button>
                    <Button style={configDefault?.theme?.prymaryBackground} onClick={onHideModalRegisterType}>Fechar</Button>
                </Modal.Footer>
            </Modal>

		</>
	);
};

export default SelectByType;