import React, {useState, useEffect, useContext} from 'react';
import './style.css';
import Main from '../../components/Dashboard/Main/index.jsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Context from "../../Context";
import Loading from '../../components/Loading/index.jsx';

const Dashboard = () => {
	const [configDefault] = useContext(Context);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if(Object.keys(configDefault).length !== 0){
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configDefault])


	return (
		<>
			<div className="area-dashboard">
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				{ (loading) ? (<Loading position="center" />) : (<Main></Main>)
				}
			</div>
		</>
	);
};

export default Dashboard;