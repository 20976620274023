import React, {useState, useEffect, useContext} from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { BiPencil, BiTrash, BiShowAlt} from "react-icons/bi";
import Header from '../Header/index.jsx';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import Pagination from '../../../../components/Pagination/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";
import ListProposalStore from '../ProposalStore/List/index.jsx';
import ListOwnerProposalStore from '../OwnerProposalStore/List/index.jsx';


const List = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('solicitation');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('solicitation');
	const [solicitations, setSolicitations] = useState([]);
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(1);
	const [qtdPages, setQtdPages] = useState(1);
	const history = useHistory();
	const api = apiService.get();
	let valuePrev = localStorage.getItem('searchValuePrev');
	const {
	    params: { column, value }
	} = match;

	const [solicitationIdSelected, setsolicitationIdSelected] = useState('');
	const [modalOwnerProposalsShow, setModalOwnerProposalsShow] = useState(false);
	const onHideModalOwnerProposal = () => {
		setModalOwnerProposalsShow(false);
	}

	const [modalProposalsShow, setModalProposalsShow] = useState(false);
	const onHideModalProposal = () => {
		setModalProposalsShow(false);
	}

	const list = () => {
		setLoading(true);
		let queryApi = '';
		if(typeof column !== "undefined" && typeof value !== "undefined"){
			if(valuePrev !== value){
				setOffset(1)
				localStorage.setItem('searchValuePrev', value);
			}
			queryApi = `api/${endpoint}/search/${column}/${value}?page=${offset}`;
		}else{
			queryApi = `api/${endpoint}?page=${offset}`;
		}
		
		api.get(queryApi)
		.then(resp => {
			let dataSolicitations = [];
			resp.data.data.solicitations.forEach(function(item, index){
				item.store_object = JSON.parse(item?.store_object);
				item.store_closed_object = JSON.parse(item?.store_closed_object);
				dataSolicitations.push(item);
			});
			setSolicitations(dataSolicitations);
			console.log(dataSolicitations);
			setQtdPages(resp.data.data.pagination.pages);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	const showModalProposals = (solicitationId) => {
		setsolicitationIdSelected(solicitationId);
		setModalProposalsShow(true);
	}

	const showModalOwnerProposals = (solicitationId) => {
		setsolicitationIdSelected(solicitationId);
		setModalOwnerProposalsShow(true);
	}

	const cancelSolicitation = (solicitationId) => {
		api.put(`api/solicitation/cancel-solicitation/${solicitationId}`, {})
		.then(resp => {
			history.push(`/dashboard/${endpoint}/list`);
			setLoading(false);
		})
		.catch(error => {
			showNotify('error', 'Ops, ocorreu algum erro!');
			//setErrors(error.message)
			verifyError(error);
		})
	}


	useEffect(() => {
		list()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [column, value, offset])


	return (
		<>
			<div className="area-crud">

				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-view`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Lista de solicitações</h5>

					{ (loading) ? (<Loading />) : (

						(solicitations?.length === 0) ? (
		                    <label>Nenhum registro encontrado...</label>
		                ) : (
		                	<div>
			                    <Table striped bordered hover>
									<thead>
										<tr>
											<th style={configDefault?.theme?.prymaryBackground}>Status</th>
											<th style={configDefault?.theme?.prymaryBackground}>Store</th>
											<th style={configDefault?.theme?.prymaryBackground}>Empresa</th>
											<th style={configDefault?.theme?.prymaryBackground}>Loja aprovada</th>
											<th style={configDefault?.theme?.prymaryBackground}>Ações</th>
										</tr>
									</thead>
									<tbody>
										{solicitations?.map((item) => (
											<tr key={item.id}>
												<td>{item.status}</td>
												<td>{item?.store_object?.name}</td>
												<td>{item?.tenant?.name}</td>
												<td>{item.store_closed_object?.name || '-'}</td>
												<td>
													<Can resource={roleEndpoint} authority={`${roleEndpoint}-view`}>
														<Link to={`/dashboard/${endpoint}/view/${item.id}`} className="btn btn-dark" style={configDefault?.theme?.prymaryBackground}>
															<BiShowAlt />
														</Link>
													</Can>

													<Can resource="store-proposal" authority={`store-proposal-create`}>
														{item.is_solicitor ? 
															<>
																<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={() => showModalProposals(item.id)}>
																	propostas
																</Button>

																{item.status !== 'concluída' ?
																	<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={() => cancelSolicitation(item.id)}>
																		cancelar
																	</Button>
																	:
																	<></>
																}
															</>
															:
															<>
																{item.status == 'aguardando' ?
																	<>
																		<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={() => showModalOwnerProposals(item.id)}>
																			minhas propostas
																		</Button>

																		<Link to={`/dashboard/${endpoint}/register-proposal/${item.id}`} className="btn btn-dark" style={configDefault?.theme?.prymaryBackground}>
																			fazer proposta
																		</Link>
																	</>
																	:
																	<></>
																}
															</>
														}
													</Can>

												</td>
											</tr>
										))}
									</tbody>
								</Table>
								<Pagination
									qtdPages={qtdPages}
									offset={offset}
									setOffset={setOffset}
								/>

							</div>
		                )
		            )}
	            </Can>
	            
			</div>

			<Modal
                show={modalOwnerProposalsShow}
                fullscreen={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <ListOwnerProposalStore solicitationId={solicitationIdSelected} setModalOwnerProposalsShow={setModalOwnerProposalsShow} />
                </Modal.Body>

                <Modal.Footer>
                    <Button style={configDefault?.theme?.prymaryBackground} onClick={onHideModalOwnerProposal}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={modalProposalsShow}
                fullscreen={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <ListProposalStore solicitationId={solicitationIdSelected} setModalProposalsShow={setModalProposalsShow} />
                </Modal.Body>

                <Modal.Footer>
                    <Button style={configDefault?.theme?.prymaryBackground} onClick={onHideModalProposal}>Fechar</Button>
                </Modal.Footer>
            </Modal>
		</>
	);
};

export default List;