import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";
import imageDefault from '../../../../assets/images/image-default-1.png';
import SelectByType from '../../../../components/SelectByType/index.jsx';

const RegisterEdit = props => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('product');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('product');

	const { id } = useParams();

	const [brandSelected, setBrandSelected] = useState({});
	const [unitsMeasurement, setUnitsMeasurement] = useState([]);
	const api = apiService.get();
	const history = useHistory();
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setValue, getValues } = useForm();
	
	const onSubmit = data => {
		let formData = new FormData();

		formData.append('name', data.name);
		formData.append('sku', data.sku);
		formData.append('code_ean', data.code_ean);
		formData.append('email', data.email);
		formData.append('qrcode', data.qrcode);
		formData.append('weight_in_grams', data.weight_in_grams);
		formData.append('brand_id', brandSelected?.id);
		formData.append('unit_measurement_id', data.unit_measurement_id);
		
		if(data?.image !== undefined && data?.image !== null) {
			if (data?.image.length > 0) {
				formData.append('image', data.image[0]);
			}
		}

		if(id !== undefined)
			update(formData);
		else
			create(formData);
	}

	const create = data => {
		setLoading(true);
		api.post(`api/${endpoint}`, data)
		.then(resp => {
			showNotify('success', 'Produto registrado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const update = data => {
		setLoading(true);

		api.post(`api/${endpoint}/${id}`, data)
		.then(resp => {
			showNotify('success', 'Produto atualizado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors);
			setLoading(false);
			verifyError(error);
		})
	}

	const initUnitMeasurement = () => {
		api.get('api/unit-measurement/all')
		.then(resp => {
			setUnitsMeasurement(resp.data.data.unitsMeasurement);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const alterImagePreview = (e, idImagePreview) => {
		//console.log(e.target.files[0]);
		let previewImage = document.getElementById(idImagePreview);
		previewImage.src = URL.createObjectURL(e.target.files[0]);
	}

	const initEndpoint = () => {
		setLoading(true);
		api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			const data = resp.data.data.product;
			setValue("name", data.name);
			setValue("sku", data.sku);
			setValue("code_ean", data.code_ean);
			setValue("email", data.email);
			setValue("qrcode", data.qrcode);
			setValue("status", data.status);
			setValue("weight_in_grams", data.weight_in_grams);
			setValue("prevImage", data.image);
			// setValue("image", data.image);
			setValue("brand_id", data.brand_id);
			setValue("unit_measurement_id", data?.unit_measurement?.id);
			setBrandSelected(data?.brand);
			
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}


	useEffect(() => {
		initUnitMeasurement();
		if(id !== undefined){
			initEndpoint();
		}else{
			document.getElementById('form-register').reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-create`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de registro</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onSubmit)} className="form-register" encType="multipart/form-data" id="form-register">
						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="name" className="form-label">Nome:</label>
								    <input type="text" name="name" className="form-control" id="name" {...register("name")} />
								    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="sku" className="form-label">Sku:</label>
								    <input type="text" name="sku" className="form-control" id="sku" {...register("sku")} />
								    <span className="error-message">{errors?.sku ? errors.sku[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="code_ean" className="form-label">Código EAN:</label>
								    <input type="text" name="code_ean" className="form-control" id="code_ean" {...register("code_ean")} />
								    <span className="error-message">{errors?.code_ean ? errors.code_ean[0] : null}</span>
								</Form.Group>
							</Col>

						</Row>


						<Row className="mb-3">
							<Col sm="2">
								<Form.Group>
								    <label htmlFor="weight_in_grams" className="form-label">Peso:</label>
								    <input type="number" name="weight_in_grams" className="form-control" id="weight_in_grams" {...register("weight_in_grams")} />
								    <span className="error-message">{errors?.weight_in_grams ? errors.weight_in_grams[0] : null}</span>
								</Form.Group>
							</Col>
							
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="qrcode" className="form-label">QRCode:</label>
								    <input type="text" name="qrcode" className="form-control" id="qrcode" {...register("qrcode")} />
								    <span className="error-message">{errors?.qrcode ? errors.qrcode[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="6">
								<Form.Group>
								    <label className="form-label">Marca :<span className="req-span">*</span></label>
							        <SelectByType
							        	crud="brand"
							        	inputSelected={brandSelected}
							        	setInputSelected={setBrandSelected}
							        >
							        </SelectByType>
							        
								    <span className="error-message">{errors?.brand_id ? errors.brand_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="6">
								<Form.Group>
								    <label htmlFor="image" className="form-label">Imagem:</label>
								    <input type="file" name="image" className="form-control" id="image" {...register("image")} onChange={(e) => alterImagePreview(e, 'img-preview')} />
								    <div className="area-preview-image">
								    	{getValues('prevImage') === null || getValues('prevImage')?.length === 0 ?
								    		<img alt="" src={imageDefault} id="img-preview" />
								    		:
								    		<img alt="" src={getValues('prevImage')} id="img-preview" />
								    	}
								    </div>
								    <span className="error-message">{errors?.image ? errors.image[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="6">
								<Form.Group>
								    <label htmlFor="unit_measurement_id" className="form-label">Unidade de medida:</label>
								    <select className="form-select" id="unit_measurement_id" name="unit_measurement_id" {...register("unit_measurement_id")}>
								    	<option value="">Selecionar</option>
							            {unitsMeasurement?.map((unitMeasurement, index) => (
								    		<option value={unitMeasurement.id} key={index}>{unitMeasurement.sigle}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.unit_measurement_id ? errors.unit_measurement_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>


					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn" style={configDefault?.theme?.prymaryBackground}>{id !== undefined ? 'Salvar' : 'Cadastrar'}</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default RegisterEdit;