import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { AiOutlineQrcode, AiOutlinePlus, AiOutlineShoppingCart } from "react-icons/ai";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import { BiSearchAlt } from "react-icons/bi";
import {useDispatch, useSelector} from 'react-redux';
import {setProducts} from '../../../../store/modules/products/actions';
import {changeQuantityProduct, plusQuantityPackages, setProduct as setProductInShoppingcart} from '../../../../store/modules/shoppingcart/actions';
import './style.css';
import apiService from '../../../../services/api.js';
import BarcodeInput from '../../BarcordeInput/index.jsx';

const SearchProductByCodeEAN = ({showShoppingButton = true}) => {
	const dispatch = useDispatch();
	const [codeEAN, setCodeEAN] = useState('');
	const [showInputSearch, setShowInputSearch] = useState(false);
	const [resetInputEan, setResetInputEan] = useState(false);
	const api = apiService.get();
	const productsShoppingcart = useSelector(state => state.shoppingcart.products);
	let idStore = JSON.parse(localStorage.getItem('toten')).store_id;

	const searchProductByEAN = () => {
		api.get(`api/store-product/by-store/${idStore}/product-by-ean/${codeEAN}`)
		.then(resp => {
			const data = resp.data.data.storeProduct;
			dispatch(setProducts(data));
			setCodeEAN('');
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			//setErrors(error.message)
			verifyError(error);
		})
	}

	const addProductByEAN = (codeEAN, isManualClick = false) => {
		if (codeEAN.length>0) {
			api.get(`api/store-product/by-store/${idStore}/product-by-ean/${codeEAN}`)
			.then(resp => {
				const data = resp.data.data.storeProduct;
				if(data.length === 0){
					showNotify('error', 'Produto não encontrado!');
				}else{
					let isAdictioned = false;
	
					productsShoppingcart.forEach(function(el, i){
						if(el?.dataProduct?.id === data[0].id) {
							isAdictioned = true;
							console.log("🚀 ~ productsShoppingcart.forEach ~ isAdictioned:", isAdictioned)
							dispatch(changeQuantityProduct(i, el?.quantity+1));
						}
					});
					if(!isAdictioned){
						dispatch(setProductInShoppingcart(data[0]));
					}
					setShowInputSearch(false);
					// }else{
						// showNotify('error', 'O produto já foi selecionado!');
						// }
				}
				setCodeEAN('');
				if (isManualClick) {
					setResetInputEan(true);
				}
			})
			.catch(error =>{
				showNotify('error', 'Ops, ocorreu algum erro!');
				//setErrors(error.message)
				verifyError(error);
			})
		}
	}

	const onChangeCodeEANManual = (event) => {
		console.log("🚀 ~ onChangeCodeEAN ~ ean:", event.target.value)
		setCodeEAN(event.target.value);
	}

	const onChangeCodeEAN = (ean) => {
		console.log("🚀 ~ onChangeCodeEAN ~ ean:", ean)
		if(ean)
			addProductByEAN(ean);
		// setCodeEAN('');
	}

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			addProductByEAN(codeEAN);
			setResetInputEan(true);
		  	setCodeEAN(''); // Clear the input field after search
		}
	};

	const toggleInputSearch = () => {
		setShowInputSearch(!showInputSearch);
	}

	return (
		<div className="search-product-by-code">
				
			<button className="btn-code"><AiOutlineQrcode size="43" onClick={toggleInputSearch}/></button>
			<div style={{display: showInputSearch ? 'flex' : 'none'}}>
				<input type="text" placeholder="Código..." style={{"width": '200px'}} onChange={(e) => onChangeCodeEANManual(e)} value={codeEAN} onKeyDown={handleKeyPress} />
				<BarcodeInput onEanChange={onChangeCodeEAN} resetInputEan={resetInputEan}/>
				<button className="btn-add-product" onClick={() => addProductByEAN(codeEAN, true)}><AiOutlinePlus size="25" /></button>
				{showShoppingButton && <button className="btn-search-product" onClick={() => searchProductByEAN()}><BiSearchAlt size="25" /></button>}

				{showShoppingButton && <Link to="../panel-toten/shopping-cart"><button className="btn-shoppingcart"><AiOutlineShoppingCart size="23" /> <span>{productsShoppingcart.length}</span></button></Link>}
			</div>

		</div>
	);
};

export default SearchProductByCodeEAN;