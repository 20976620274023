import React, {useState, useEffect, useContext} from 'react';
import { Table, Button, ListGroup, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BiPencil, BiTrash, BiShowAlt, BiMenu} from "react-icons/bi";
import { IoAddCircleOutline } from "react-icons/io5";
import apiService from '../../services/api.js';
import Loading from '../Loading/index.jsx';
import { useHistory } from "react-router-dom";
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../services/actionsAppService.jsx';
import Context from "../../Context";

const EndpointsTypesService = () => {
	const [configDefault, setConfigDefault] = useContext(Context);
	const [errors, setErrors] = useState('');
	const api = apiService.get();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [brands, setBrands] = useState([]);
	const [unitsMeasurement, setUnitsMeasurement] = useState([]);
	const [nameTypeSelected, setNameTypeSelected] = useState({});
	const [idTypeSelected, setIdTypeSelected] = useState({});
	const [typeForm, setTypeForm] = useState('');
	const [typeCrudSelected, setTypeCrudSelected] = useState('');

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [showFormUnitMeasurement, setShowFormUnitMeasurement] = useState(false);
	const handleCloseFormUnitMeasurement = () => setShowFormUnitMeasurement(false);
	const handleShowFormUnitMeasurement = () => setShowFormUnitMeasurement(true);
	const [sigleUnitMeasurement, setSigleUnitMeasurement] = useState({});

	const listBrands = () => {
		setLoading(true);

		api.get(`api/brand/all`)
		.then(resp => {
			setBrands(resp.data.data.brands);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	const listUnitsMeasurement = () => {
		setLoading(true);

		api.get(`api/unit-measurement/all`)
		.then(resp => {
			setUnitsMeasurement(resp.data.data.unitsMeasurement);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	const submitType = (event) => {
		setLoading(true);
		event.preventDefault();
		let data = {
			name: event.target.name.value
		}
		let query = ``;
		if(typeForm === 'edit')
			query = `api/${typeCrudSelected}/${idTypeSelected}`;
		else
			query = `api/${typeCrudSelected}`;
		api.post(query, data)
		.then(resp => {
			showNotify('success', 'Ação realizada com sucesso!');
			listBrands();
			listUnitsMeasurement();
			setLoading(false);
			history.push('/dashboard/admin/config/overview');
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors);
			setLoading(false);
			verifyError(error);
		})
		handleClose();
	}

	const submitUnitMeasurement = (event) => {
		setLoading(true);
		event.preventDefault();
		let data = {
			name: event.target?.name?.value,
			sigle: event.target?.sigle?.value,
		}
		let query = ``;
		if(typeForm === 'edit')
			query = `api/${typeCrudSelected}/${idTypeSelected}`;
		else
			query = `api/${typeCrudSelected}`;
		api.post(query, data)
		.then(resp => {
			showNotify('success', 'Ação realizada com sucesso!');
			listBrands();
			listUnitsMeasurement();
			setLoading(false);
			history.push('/dashboard/admin/config/overview');
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors);
			setLoading(false);
			verifyError(error);
		})
		handleCloseFormUnitMeasurement();
	}

	const deleteType = (typeCrud, id) => {
		setLoading(true);
		api.delete(`api/${typeCrud}/${id}`)
		.then(resp => {
			showNotify('success', 'Ação realizada com sucesso!');
			listBrands();
			listUnitsMeasurement();
			setLoading(false);
			history.push('/dashboard/admin/config/overview');
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors);
			setLoading(false);
			verifyError(error);
		})
	}

	const toggleListTypes = (id) => {
		let itemsListsTypes = document.getElementsByClassName(`item-list-${id}`);
		for(let i = 0; i < itemsListsTypes.length; i++){
			if(itemsListsTypes[i].style.display == 'none' || itemsListsTypes[i].style.display == '')
				itemsListsTypes[i].style.display = 'block';
			else
				itemsListsTypes[i].style.display = 'none';
		}
	}

	const registerEditType = (typeCrud, obj = null) => {
		setTypeCrudSelected(typeCrud);
		if(obj == null){
			setTypeForm('register');
			handleShow();
		}else{
			setIdTypeSelected(obj.id);
			setNameTypeSelected(obj.name);
			setTypeForm('edit');
			handleShow();
		}
	}

	const registerEditUnitMeasurement = (typeCrud, obj = null) => {
		setTypeCrudSelected(typeCrud);
		if(obj == null){
			setTypeForm('register');
			handleShowFormUnitMeasurement();
		}else{
			setIdTypeSelected(obj.id);
			setNameTypeSelected(obj.name);
			setSigleUnitMeasurement(obj.sigle);
			setTypeForm('edit');
			handleShowFormUnitMeasurement();
		}
	}

	useEffect(() => {
		listBrands();
		listUnitsMeasurement();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Can 
				resource="type-crud"
				authority="type-crud-view"
				otherwiseComponent={<label>Sem acesso!</label>}
			>
				<h5 className="mb-3"></h5>

				{ (loading) ? (<Loading />) : (
					<>
						{/*Listagens de tipagens*/}
						<h5 className="mb-4">Tipagens</h5>
	                	<div className="row mb-4">
	                		<div className="col-6">
	                			<div className="col-12 mb-4">
			                		<ListGroup className="list-type" as="ul">
										<ListGroup.Item as="li" active style={configDefault?.theme?.prymaryBackground}>
									    	Marcas
									    	<button className="btn-transparent" onClick={(e) => { registerEditType('brand')}}><IoAddCircleOutline /></button>
									    	<button className="btn-transparent" onClick={(e) => { toggleListTypes(1)}}><BiMenu /></button>
										</ListGroup.Item>
										
										{brands?.map((type) => (
											<ListGroup.Item className="item-list-1" as="li" key={type?.id}>
												{type?.name}
												<Can resource="type-crud" authority="type-crud-update">
													<button type="button" className="btn-transparent" onClick={(e) => { registerEditType('brand', type)}}>
														<BiPencil />
													</button>
												</Can>
												<Can resource="type-crud" authority="type-crud-delete">
													<button type="button" className="btn-transparent" onClick={(e) => {if (window.confirm('Você realmente deseja excluir este registro?')) deleteType('brand', type.id, e)}}>
														<BiTrash />
													</button>
												</Can>
											</ListGroup.Item>
										))}
									</ListGroup>
								</div>
	                		</div>

	                		<div className="col-6">
								<div className="col-12 mb-4">
			                		<ListGroup className="list-type" as="ul">
										<ListGroup.Item as="li" active style={configDefault?.theme?.prymaryBackground}>
									    	Unidades de medida
									    	<button className="btn-transparent" onClick={(e) => { registerEditUnitMeasurement('unit-measurement')}}><IoAddCircleOutline /></button>
									    	<button className="btn-transparent" onClick={(e) => { toggleListTypes(2)}}><BiMenu /></button>
										</ListGroup.Item>
										
										{unitsMeasurement?.map((type) => (
											<ListGroup.Item className="item-list-2" as="li" key={type?.id}>
												{type?.name} - {type?.sigle}
												<Can resource="type-crud" authority="type-crud-update">
													<button type="button" className="btn-transparent" onClick={(e) => { registerEditUnitMeasurement('unit-measurement', type)}}>
														<BiPencil />
													</button>
												</Can>
												<Can resource="type-crud" authority="type-crud-delete">
													<button type="button" className="btn-transparent" onClick={(e) => {if (window.confirm('Você realmente deseja excluir este registro?')) deleteType('unit-measurement', type.id, e)}}>
														<BiTrash />
													</button>
												</Can>
											</ListGroup.Item>
										))}
									</ListGroup>
								</div>
							</div>						
		                </div>


		                {/*Formulário de registro e edicao de tipagens*/}
						<Modal show={show} onHide={handleClose}>
					        <Modal.Header closeButton>
					        	<Modal.Title>{typeForm == 'register' ? 'Registrar' : 'Editar'}</Modal.Title>
					        </Modal.Header>
					        <Modal.Body>
					        	<Form onSubmit={submitType}>
					        		<Row className="mb-3">
										<Col sm="12">
											<Form.Group>
											    <label htmlFor="name" className="form-label">Nome:<span className="req-span">*</span></label>
											    {typeForm == 'edit' ? 
											    	<input type="text" name="name" className="form-control" id="name" defaultValue={nameTypeSelected} />
											    	:
											    	<input type="text" name="name" className="form-control" id="name" />
											    }
											    
											    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
											</Form.Group>
										</Col>
									</Row>

									<Row>
										<Col sm="12">
											<Button type="submit" variant="primary" style={configDefault?.theme?.prymaryBackground}>Salvar</Button>
							        	</Col>
									</Row>
					        	</Form>
					        </Modal.Body>
					    </Modal>


						{/*Formulário de registro e edicao de tipagens com mais de 1 campo*/}
						<Modal show={showFormUnitMeasurement} onHide={handleCloseFormUnitMeasurement}>
					        <Modal.Header closeButton>
					        	<Modal.Title>{typeForm == 'register' ? 'Registrar' : 'Editar'}</Modal.Title>
					        </Modal.Header>
					        <Modal.Body>
					        	<Form onSubmit={submitUnitMeasurement}>
					        		{typeForm == 'edit' ? 
										<>
							        		<Row className="mb-3">
												<Col sm="12">
													<Form.Group>
													    
												    	<label htmlFor="name" className="form-label">Nome:<span className="req-span">*</span></label>
												    	<input type="text" name="name" className="form-control" id="name" defaultValue={nameTypeSelected} />

												    	<label htmlFor="sigle" className="form-label">Sigla:<span className="req-span">*</span></label>
												    	<input type="text" name="sigle" className="form-control" id="sigle" defaultValue={sigleUnitMeasurement} />
													    	
													    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
													</Form.Group>
												</Col>
											</Row>
										</>
										:
										<>
							        		<Row className="mb-3">
												<Col sm="12">
													<Form.Group>
													    
												    	<label htmlFor="name" className="form-label">Nome:<span className="req-span">*</span></label>
												    	<input type="text" name="name" className="form-control" id="name" />

												    	<label htmlFor="sigle" className="form-label">Sigla:<span className="req-span">*</span></label>
												    	<input type="text" name="sigle" className="form-control" id="sigle" />
													    	
													    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
													</Form.Group>
												</Col>
											</Row>
										</>
									}

									<Row>
										<Col sm="12">
											<Button type="submit" variant="primary" style={configDefault?.theme?.prymaryBackground}>Salvar</Button>
							        	</Col>
									</Row>
					        	</Form>
					        </Modal.Body>
					    </Modal>
	                </>
	            )}
            </Can>
		</>
	);
}

export default EndpointsTypesService;