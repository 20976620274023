import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiLocations from '../../../../services/apiLocations.js';
import apiService from '../../../../services/api.js';
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";
import InputMask from "react-input-mask";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt-BR';

const RegisterEdit = props => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('customer');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('customer');

	const { id } = useParams();

	registerLocale('pt-BR', pt)
	const api = apiService.get();
	const history = useHistory();
	const [users, setUsers] = useState([]);
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const [birthDate, setBirthDate] = useState(new Date());
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setValue, getValues } = useForm();
	
	const onSubmit = data => {
		data.birth_date = `${birthDate.getDate()}/${birthDate.getMonth()+1}/${birthDate.getFullYear()}`;
		data.uf = states.find(function(el){
		    return el.id == data.state_id;/* eslint-disable-line */
		})?.sigla;

		data.state = states.find(function(el){
		    return el.id == data.state_id;/* eslint-disable-line */
		})?.nome;

		data.city = cities.find(function(el){
		    return el.id == data.city_id;/* eslint-disable-line */
		})?.nome;

		if(id !== undefined)
			update(data);
		else
			create(data);
	}

	const create = data => {
		setLoading(true);
		
		api.post(`api/${endpoint}`, data)
		.then(resp => {
			showNotify('success', 'Cliente registrado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const update = data => {
		setLoading(true);

		api.put(`api/${endpoint}/${id}`, data)
		.then(resp => {
			showNotify('success', 'Cliente atualizado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors);
			setLoading(false);
			verifyError(error);
		})
	}

	const onChangeState = (ev) => {
		const { value } = ev.target;
		
		apiLocations.get(`estados/${value}/municipios`)
		.then((res) => {
			setCities(res.data);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			console.log(error);
		})
	}

	const initUsers = () => {
		api.get('api/user/all')
		.then(resp => {
			setUsers(resp.data.data.users);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const initStates = () => {
	    apiLocations.get('estados')
		.then((res) => {
			setStates(res.data);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			console.log(error);
		})
	}

	const initCities = (idState) => {
		apiLocations.get(`estados/${idState}/municipios`)
		.then((res) => {
			setCities(res.data)
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			console.log(error);
		})
	}
	
	const initEndpoint = () => {
		setLoading(true);
		api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			const data = resp.data.data.customer;
			setValue("name", data.name);
			setValue("surname", data.surname);
			setValue("cpf", data.cpf);
			setValue("email", data.email);
			setValue("phone_number", data.phone_number || '');
			setValue("cellphone_number", data.cellphone_number || '');
			setValue("is_prospecting", data.is_prospecting);
			setValue("user_id", data.user_id);
			setValue("zipcode", data?.address?.zipcode || '');
			setValue("state_id", data?.address?.state_id);
			setValue("city_id", data?.address?.city_id);
			setValue("street", data?.address?.street);
			setValue("neighborhood", data?.address?.neighborhood);
			setValue("number", data?.address?.number);
			setValue("complement", data?.address?.complement);

			let birth_date_arr = data.birth_date.split('/');
			setBirthDate(new Date(birth_date_arr[2], (birth_date_arr[1]-1), birth_date_arr[0]));

			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	useEffect(() => {
		initUsers()
		initStates()
		if(id !== undefined){
			initEndpoint();
			initCities(getValues("state_id"));
		}else{
			document.getElementById('form-register').reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, getValues("state_id")])

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-create`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de registro</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onSubmit)} className="form-register" id="form-register">
						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="name" className="form-label">Nome:<span className="req-span">*</span></label>
								    <input type="text" name="name" className="form-control" id="name" {...register("name")} />
								    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="surname" className="form-label">Sobrenome:<span className="req-span">*</span></label>
								    <input type="text" name="surname" className="form-control" id="surname" {...register("surname")} />
								    <span className="error-message">{errors?.surname ? errors.surname[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="email" className="form-label">Email:</label>
								    <input type="email" name="email" className="form-control" id="email" {...register("email")} />
								    <span className="error-message">{errors?.email ? errors.email[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="cpf" className="form-label">CPF:<span className="req-span">*</span></label>
								    <InputMask mask="999.999.999-99" name="cpf" className="form-control" id="cpf" {...register("cpf")} />
								    <span className="error-message">{errors?.cpf ? errors.cpf[0] : null}</span>
								</Form.Group>
							</Col>


							<Col sm="4">
								<Form.Group>
								    <label htmlFor="user_id" className="form-label">Usuário:<span className="req-span">*</span></label>
								    <select className="form-select" name="user_id" {...register("user_id")}>
								    	<option value="">Selecionar</option>
							            {users?.map((user, index) => (
								    		<option value={user.id} key={index}>{user.name}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.user_id ? errors.user_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
									<label htmlFor="phone_number" className="form-label">Telefone fixo:</label>
									<InputMask mask="(99) 9999-9999" placeholder='(xx) xxxx-xxxx' name="phone_number" className="form-control" id="phone_number" {...register("phone_number")} />
									<span className="error-message">{errors?.phone_number ? errors.phone_number[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">

							<Col sm="4">
								<Form.Group>
									<label htmlFor="cellphone_number" className="form-label">Telefone móvel:</label>
									<InputMask mask="(99) 99999-9999" placeholder='(xx) xxxxx-xxxx' name="cellphone_number" className="form-control" id="cellphone_number" {...register("cellphone_number")} />
									<span className="error-message">{errors?.cellphone_number ? errors.cellphone_number[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="is_prospecting" className="form-label">Cliente de prospecção:</label>
								    <select className="form-select" name="is_prospecting" {...register("is_prospecting")}>
								    	<option value="">Selecionar</option>
								    	<option value="1">Sim</option>
										<option value="0">Não</option>
							        </select>
								    <span className="error-message">{errors?.is_prospecting ? errors.is_prospecting[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="birth_date" className="form-label">Data de nascimento:</label>
								    <DatePicker className="form-control" locale="pt-BR" dateFormat="P" selected={birthDate} onChange={(date:Date) => setBirthDate(date)} />
								    <span className="error-message">{errors?.birth_date ? errors.birth_date[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="3">
								<Form.Group>
								    <label htmlFor="zipcode" className="form-label">CEP:<span className="req-span">*</span></label>
								    <InputMask mask="99999-999" name="zipcode" className="form-control" id="zipcode" {...register("zipcode")} />
								    <span className="error-message">{errors?.zipcode ? errors.zipcode[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="state" className="form-label">Estado:<span className="req-span">*</span></label>
								    <select className="form-select" name="state_id" {...register("state_id")} onChange={(ev) => onChangeState(ev)} >
								    	<option value="">Selecionar</option>
								    	{states?.map((state, index) => (
								    		<option value={state.id} key={index}>{state.sigla}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.state_id ? errors.state_id[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="5">
								<Form.Group>
								    <label htmlFor="city" className="form-label">Cidade:<span className="req-span">*</span></label>
								    <select className="form-select" name="city_id" {...register("city_id")} >
								    	<option value="">Selecionar</option>
							            {cities?.map((city, index) => (
								    		<option value={city.id} key={index}>{city.nome}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.city_id ? errors.city_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="5">
								<Form.Group controlId="formBasicEmail">
								    <label htmlFor="street" className="form-label">Endereço:<span className="req-span">*</span></label>
								    <input type="text" name="street" className="form-control" id="street" {...register("street")} />
								    <span className="error-message">{errors?.street ? errors.street[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="5">
								<Form.Group controlId="formBasicEmail">
								    <label htmlFor="neighborhood" className="form-label">Bairro:<span className="req-span">*</span></label>
								    <input type="text" name="neighborhood" className="form-control" id="neighborhood" {...register("neighborhood")} />
								    <span className="error-message">{errors?.neighborhood ? errors.neighborhood[0] : null}</span>
								</Form.Group>
							</Col>
							<Col sm="2">
								<Form.Group controlId="formBasicEmail">
								    <label htmlFor="number" className="form-label">Nº:<span className="req-span">*</span></label>
								    <input type="text" name="number" maxlength="6" className="form-control" id="number" {...register("number")} />
								    <span className="error-message">{errors?.number ? errors.number[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="12">
								<Form.Group>
								    <label htmlFor="complement" className="form-label">Complemento:</label>
								    <input type="text" name="complement" className="form-control" id="complement" {...register("complement")} />
								    <span className="error-message">{errors?.complement ? errors.complement[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn" style={configDefault?.theme?.prymaryBackground}>{id !== undefined ? 'Salvar' : 'Cadastrar'}</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default RegisterEdit;