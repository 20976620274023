import React, {useState, useContext} from 'react';
import { BiSearch } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import { Can } from "react-access-level";
import { useHistory } from "react-router-dom";
import Context from "../../../../Context";

const Header = () => {
    const [configDefault] = useContext(Context);
    const history = useHistory();
    const [column, setColumn] = useState('name');
    const [value, setValue] = useState('');
    // eslint-disable-next-line
    const [endpoint, setEndpoint] = useState('product');
    // eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('product');

    const changeColumn = (ev) => {
        setColumn(ev.target.value);
    }

    const changeValue = (ev) => {
        setValue(ev.target.value.trim());
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if(value === '')
            history.push(`/dashboard/${endpoint}/list`);
        else
            history.push(`/dashboard/${endpoint}/search/${column}/${value}`);
    }

	return (
		<>
			<h2 className="title-endpoint">Produtos</h2>

            <div className="row area-actions-crud">
                <div className="group-buttons">
                    <Can resource={roleEndpoint} authority={`${roleEndpoint}-create`}>
                    	<Link to={`/dashboard/${endpoint}/register`} className="btn btn-dark first" style={configDefault?.theme?.prymaryBackground}>Adicionar</Link>
                    </Can>

                    <Can resource={roleEndpoint} authority={`${roleEndpoint}-view`}>
                        <Link to={`/dashboard/${endpoint}/list`} className="btn btn-dark last" style={configDefault?.theme?.prymaryBackground}>Listar</Link>
                    </Can>
                </div>

                <Can resource={roleEndpoint} authority={`${roleEndpoint}-view`}>
                    <Form onSubmit={onSubmit}>
                        <select className="form-select" name="column" onChange={(ev) => changeColumn(ev)}>
                            <option value="name">Nome</option>
                            <option value="sku">Sku</option>
                            <option value="code_ean">Código EAN</option>
                        </select>
                        <InputGroup className="mb-3 input-search">
                            <FormControl placeholder="Buscar" onChange={(ev) => changeValue(ev)} />
                            <Button className="btn btn-dark" type="submit" style={configDefault?.theme?.prymaryBackground}><BiSearch /></Button>
                        </InputGroup>
    	            </Form>
                </Can>
            </div>
        </>
	);
};

export default Header;