import React, {useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AiOutlineInbox, AiOutlineSolution } from "react-icons/ai";
import { TbReceiptTax } from "react-icons/tb";
import { SiDropbox } from "react-icons/si";
import { BiTachometer, BiHistory, BiGroup, BiStoreAlt, BiPackage, BiCategoryAlt, BiBasket, BiDesktop, BiMenu, BiLock, BiUser } from "react-icons/bi";
import { IoIosPeople } from "react-icons/io";
import Context from "../../../Context";
import { Can, or } from "react-access-level";
import './style.css';

const Sidebar = () => {
	const [configDefault] = useContext(Context);
	const [toggleNav, setToggleNav] = useState(true);

	const toggleSidebar = () => {
		//console.log(configDefault);
		const sidebarElement = document.getElementById('sidebar');
		const btnToggleSidebar = document.getElementById('btn-toggle-sidebar');
		const headerSidebar = document.querySelector('.header-sidebar');
		const lbsLinkSidebar = document.getElementsByClassName('lb-link-sidebar');
		
		if(toggleNav){
			sidebarElement.style.width = '73px';
			sidebarElement.style.paddingTop = '50px';
			btnToggleSidebar.style.marginLeft = '18px';
			headerSidebar.style.display = 'none';
			
			for(let i = 0; i < lbsLinkSidebar.length; i++){
				lbsLinkSidebar[i].style.display = 'none';
			}
			setToggleNav(false);
		}else {
			sidebarElement.style.width = '300px';
			sidebarElement.style.paddingTop = '0px';
			btnToggleSidebar.style.marginLeft = '-20px';
			headerSidebar.style.display = 'block';
			for(let i = 0; i < lbsLinkSidebar.length; i++){
				lbsLinkSidebar[i].style.display = 'block';
			}
			setToggleNav(true);
		}
	}


	return (
		<>
			<div className="sidebar" id="sidebar" style={configDefault?.theme?.sidebarBgColor}>
				<div className="header-sidebar">
					<a href="/dashboard" className="text-decoration-none" style={configDefault?.theme?.sidebarFontColor}><img className="logo-sidebar" src={`${process.env.PUBLIC_URL}/ss-logo.png`} alt="logo" /></a>
				</div>

				<button type="button" className="btn-toggle-sidebar" id="btn-toggle-sidebar" onClick={toggleSidebar} style={configDefault?.theme?.prymaryBackground}><BiMenu size="20px" /></button>

				<div className="area-links">
					<Can match={or("dashboard:dashboard-view", "dashboard:dashboard-delete", "dashboard:dashboard-update", "dashboard:dashboard-create")}>
			            <NavLink exact to="/dashboard" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<BiTachometer size="25px" /><label className="lb-link-sidebar">Dashboard</label>
			            </NavLink>
		            </Can>

		            <Can match={or("user:user-view", "user:user-delete", "user:user-update", "user:user-create")}>
			            <NavLink to="/dashboard/user/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<BiGroup size="25px" /><label className="lb-link-sidebar">Usuários</label>
			            </NavLink>
		            </Can>

		            <Can match={or("store:store-view", "store:store-delete", "store:store-update", "store:store-create")}>
			            <NavLink to="/dashboard/store/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<BiStoreAlt size="25px" /><label className="lb-link-sidebar">Lojas</label>
			            </NavLink>
		            </Can>

		            <Can match={or("toten:toten-view", "toten:toten-delete", "toten:toten-update", "toten:toten-create")}>
			            <NavLink to="/dashboard/toten/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<BiDesktop size="25px" /><label className="lb-link-sidebar">PDV</label>
			            </NavLink>
		            </Can>

		            <Can match={or("product:product-view", "product:product-delete", "product:product-update", "product:product-create")}>
			            <NavLink to="/dashboard/product/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<BiPackage size="25px" /><label className="lb-link-sidebar">Produtos</label>
			            </NavLink>
		            </Can>

		            <Can match={or("batch-product:batch-product-view", "batch-product:batch-product-delete", "batch-product:batch-product-update", "batch-product:batch-product-create")}>
			            <NavLink to="/dashboard/batch-product/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<AiOutlineInbox size="25px" /><label className="lb-link-sidebar">Estoque</label>
			            </NavLink>
		            </Can>

		            <Can match={or("inventory-store:inventory-store-view", "inventory-store:inventory-store-delete", "inventory-store:inventory-store-update", "inventory-store:inventory-store-create")}>
			            <NavLink to="/dashboard/inventory-store/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<BiHistory size="25px" /><label className="lb-link-sidebar">Histórico das operações</label>
			            </NavLink>
		            </Can>

		            <Can match={or("tax:tax-view", "tax:tax-delete", "tax:tax-update", "tax:tax-create")}>
			            <NavLink to="/dashboard/tax/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<TbReceiptTax size="25px" /><label className="lb-link-sidebar">Impostos</label>
			            </NavLink>
		            </Can>

		            <Can match={or("customer:customer-view", "customer:customer-delete", "customer:customer-update", "customer:customer-create")}>
			            <NavLink to="/dashboard/customer/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<IoIosPeople size="25px" /><label className="lb-link-sidebar">Clientes</label>
			            </NavLink>
		            </Can>

		            <Can match={or("package-product:package-product-view", "package-product:package-product-delete", "package-product:package-product-update", "package-product:package-product-create")}>
			            <NavLink to="/dashboard/package-product/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<SiDropbox size="25px" /><label className="lb-link-sidebar">Atacado</label>
			            </NavLink>
		            </Can>		

		            <Can match={or("purchase:purchase-view", "purchase:purchase-delete", "purchase:purchase-update", "purchase:purchase-create")}>
			            <NavLink to="/dashboard/purchase/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<BiBasket size="25px" /><label className="lb-link-sidebar">Compras</label>
			            </NavLink>
		            </Can>

		            <Can match={or("solicitation:solicitation-view", "solicitation:solicitation-delete", "solicitation:solicitation-update", "solicitation:solicitation-create")}>
			            <NavLink to="/dashboard/solicitation/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<AiOutlineSolution size="25px" /><label className="lb-link-sidebar">Solicitações</label>
			            </NavLink>
		            </Can>

					<Can match={or("category:category-view", "category:category-delete", "category:category-update", "category:category-create")}>
			            <NavLink to="/dashboard/category/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<BiCategoryAlt size="25px" /><label className="lb-link-sidebar">Categorias</label>
			            </NavLink>
		            </Can>

		            <Can match={or("acl:acl-view", "acl:acl-delete", "acl:acl-update", "acl:acl-create")}>
			            <NavLink to="/dashboard/access-control/list" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
			            	<BiLock size="25px" /><label className="lb-link-sidebar">Controle de acesso</label>
			            </NavLink>
		            </Can>

		            <NavLink to="/dashboard/profile/view" activeClassName="activeClicked" style={configDefault?.theme?.sidebarFontColor}>
		            	<BiUser size="25px" /><label className="lb-link-sidebar">Meu perfil</label>
		            </NavLink>
				</div>
			</div>
		</>
	);
};

export default Sidebar;