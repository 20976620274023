import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NumericFormat } from 'react-number-format';
import SelectByType from '../../../../components/SelectByType/index.jsx';
import pt from 'date-fns/locale/pt-BR';

const RegisterEdit = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('batch-product');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('batch-product');

	const {
	    params: { id }
	} = match;

	const [stores, setStores] = useState([]);

	registerLocale('pt-BR', pt)
	const api = apiService.get();
	const history = useHistory();
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setValue, getValues } = useForm();

	const [brandSelected, setBrandSelected] = useState({});
	const [unitsMeasurement, setUnitsMeasurement] = useState([]);
	const [dateFabrication, setDateFabrication] = useState(new Date());
	const [dateValidate, setDateValidate] = useState(new Date());
	const [storeProducts, setStoreProducts] = useState([]);
	const [storeId, setStoreId] = useState('');
	
	const onSubmit = data => {
		data.date_fabrication = `${dateFabrication.getDate()}/${dateFabrication.getMonth()+1}/${dateFabrication.getFullYear()}`;
		data.date_validate = `${dateValidate.getDate()}/${dateValidate.getMonth()+1}/${dateValidate.getFullYear()}`;
		data.quantity = parseFloat(getValues('quantity'));
		data.brand_id = brandSelected?.id;
		if(id !== undefined)
			update(data);
		else
			create(data);
	}

	const create = data => {
		setLoading(true);
		
		api.post(`api/${endpoint}`, data)
		.then(resp => {
			showNotify('success', 'Estoque registrado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const update = data => {
		setLoading(true);

		api.put(`api/${endpoint}/${id}`, data)
		.then(resp => {
			showNotify('success', 'Estoque atualizado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors);
			setLoading(false);
			verifyError(error);
		})
	}

	const initUnitMeasurement = () => {
		api.get('api/unit-measurement/all')
		.then(resp => {
			setUnitsMeasurement(resp.data.data.unitsMeasurement);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const initStores = () => {
		api.get('api/store/all')
		.then(resp => {
			setStores(resp.data.data.stores);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const onChangeStore = (e) => {
		let idStore = e.target.value;
		initStoreProducts(idStore);
	}

	
	const initStoreProducts = async (idStore = null) => {
		api.get(`api/store-product/by-store/${idStore}`)
		.then(resp => {
			setStoreProducts(resp.data.data.storeProducts);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	
	const initEndpoint = () => {
		setLoading(true);
		api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			const data = resp.data.data.batchProduct;
			setValue("batch_number", data.batch_number);
			setValue("quantity", data.quantity);
			setValue("type_batch", data.type_batch);
			setValue("product_store_id", data.product_store_id);
			setValue("brand_id", data.brand_id);
			setStoreId(data?.product_store?.store?.id);

			let date_fabrication_arr = data.date_fabrication.split('/');
			setDateFabrication(new Date(date_fabrication_arr[2], (date_fabrication_arr[1]-1), date_fabrication_arr[0]));

			let date_validate_arr = data.date_validate.split('/');
			setDateValidate(new Date(date_validate_arr[2], (date_validate_arr[1]-1), date_validate_arr[0]));
			
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}


	useEffect(() => {
		if(id !== undefined && storeId !== ''){
			initStoreProducts(storeId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storeId])


	useEffect(() => {
		initUnitMeasurement();
		initStores();
		if(id !== undefined){
			initEndpoint();
		}else{
			document.getElementById('form-register').reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-create`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de registro</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onSubmit)} className="form-register" id="form-register">
						<Row className="mb-3">
							<Col sm="3">
								<Form.Group>
								    <label htmlFor="batch_number" className="form-label">Número do lote:<span className="req-span">*</span></label>
								    <input type="text" name="batch_number" className="form-control" id="batch_number" {...register("batch_number")} />
								    <span className="error-message">{errors?.batch_number ? errors.batch_number[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="3">
								<Form.Group>
								    <label htmlFor="quantity" className="form-label">Quantidade:<span className="req-span">*</span></label>
								    <NumericFormat displayType="input" name="quantity" className="form-control" id="quantity" onChange={(e) => setValue("quantity", e.target.value)} />
								    <span className="error-message">{errors?.quantity ? errors.quantity[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="3">
								<Form.Group>
								    <label htmlFor="store_id" className="form-label">Loja:</label>
								    <select className="form-select" id="store_id" name="store_id" onChange={(e) => onChangeStore(e)}>
								    	<option value="">Selecionar</option>
							            {stores?.map((store, index) => (
								    		<option value={store?.id} selected={storeId === store?.id ? true : false} key={index}>{store?.name}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.store_id ? errors.store_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="3">
								<Form.Group>
								    <label htmlFor="type_batch" className="form-label">Tipo de estoque:</label>
								    <select className="form-select" id="type_batch" name="type_batch" {...register("type_batch")}>
								    	<option value="">Selecionar</option>
								    	<option value="entry">Entrada</option>
								    	<option value="out">Saída</option>
								    	<option value="purchase">Compra</option>
								    	<option value="devolution">Devolução</option>
							        </select>
								    <span className="error-message">{errors?.type_batch ? errors.type_batch[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>


						<Row className="mb-3">
							<Col sm="6">
								<Form.Group>
								    <label className="form-label">Marca :<span className="req-span">*</span></label>
							        <SelectByType
							        	crud="brand"
							        	inputSelected={brandSelected}
							        	setInputSelected={setBrandSelected}
							        >
							        </SelectByType>
							        
								    <span className="error-message">{errors?.brand_id ? errors.brand_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="6">
								<Form.Group>
								    <label htmlFor="unit_measurement_id" className="form-label">Unidade de medida:</label>
								    <select className="form-select" id="unit_measurement_id" name="unit_measurement_id" {...register("unit_measurement_id")}>
								    	<option value="">Selecionar</option>
							            {unitsMeasurement?.map((unitMeasurement, index) => (
								    		<option value={unitMeasurement.id} key={index}>{unitMeasurement.sigle}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.unit_measurement_id ? errors.unit_measurement_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>



						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="product_store_id" className="form-label">Produto da loja:</label>
								    <select className="form-select" id="product_store_id" name="product_store_id" {...register("product_store_id")}>
								    	<option value="">Selecionar</option>
							            {storeProducts?.map((storeProduct, index) => (
								    		<option value={storeProduct.id} selected={getValues('product_store_id') === storeProduct?.id ? true : false} key={index}>{storeProduct?.product?.name}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.product_store_id ? errors.product_store_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="date_fabrication" className="form-label">Data de fabricação:</label>
								    <DatePicker className="form-control" locale="pt-BR" dateFormat="P" selected={dateFabrication} onChange={(date:Date) => setDateFabrication(date)} />
								    <span className="error-message">{errors?.date_fabrication ? errors.date_fabrication[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="date_validate" className="form-label">Data de validade:</label>
								    <DatePicker className="form-control" locale="pt-BR" dateFormat="P" selected={dateValidate} onChange={(date:Date) => setDateValidate(date)} />
								    <span className="error-message">{errors?.date_validate ? errors.date_validate[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn" style={configDefault?.theme?.prymaryBackground}>{id !== undefined ? 'Salvar' : 'Cadastrar'}</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default RegisterEdit;