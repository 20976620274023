import React from 'react';
import ListProducts from './ListProducts/index.jsx';
import Header from './Header/index.jsx';
import TotalValue from './TotalValue/index.jsx';
import './style.css';

const ShoppingCart = () => {
	return (
		<div className="page-self-checkout">
			<Header />
			<div className="container-self-checkout">
				<ListProducts />
				<TotalValue />
			</div>
		</div>
	);
};

export default ShoppingCart;