import React, {useState, useEffect, useContext} from 'react';
import { useForm } from "react-hook-form";
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../../services/api.js';
import Loading from '../../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../../services/actionsAppService.jsx';
import Context from "../../../../../Context";
import InputMask from "react-input-mask";

const ConfigDataCompany = () => {
	// eslint-disable-next-line
	const [configDefault, setConfigDefault] = useContext(Context);

	const [loading, setLoading] = useState(false);
	const api = apiService.get();
	const [errors, setErrors] = useState('');
	const [whatsapp, setWhatsapp] = useState('+55 (00) 00000 - 0000');
	const [hourOpen, setHourOpen] = useState('00:00');
	const [hourClose, setHourClose] = useState('00:00');
	const onChangeWhatsapp = event => { setWhatsapp(event.target.value); }
	const onChangeHourOpen = event => { setHourOpen(event.target.value); }
	const onChangeHourClose = event => { setHourClose(event.target.value); }

	const { register, handleSubmit, setValue } = useForm();

	const init = () => {
		setLoading(true);
		api.get(`api/company/first`)
		.then(resp => {
			const data = resp.data;
			setHourOpen(data.hour_open);
			setHourClose(data.hour_close);
			setWhatsapp(data.whatsapp);
			setValue("name", data.name);
			setValue("open_sunday", data.open_sunday);
			setValue("open_monday", data.open_monday);
			setValue("open_tuesday", data.open_tuesday);
			setValue("open_wednesday", data.open_wednesday);
			setValue("open_thursday", data.open_thursday);
			setValue("open_friday", data.open_friday);
			setValue("open_saturday", data.open_saturday);
			setValue("whatsapp", data.whatsapp);
			setValue("facebook", data.facebook);
			setValue("instagram", data.instagram);

			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}


	const onSubmit = data => {
		setLoading(true);

		data.hour_open = hourOpen;
		data.hour_close = hourClose;
		data.whatsapp = whatsapp;

		api.put(`api/company/change-data`, data)
		.then(resp => {
			showNotify('success', 'Dados da empresa atualizados com sucesso!');
			document.location.reload(true);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	

	return (
		<>
			<Can 
				resource="company"
				authority="company-update"
				otherwiseComponent={<label>Sem acesso!</label>}
			>
				{ (loading) ? (<Loading />) : (
					<>
						{/*Configuração dos dados da empresa*/}
					    <h5 className="mb-4">Dados da empresa</h5>
                		<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                			<Row className="mb-3">
                				<Col sm="6">
									<Form.Group>
									    <label htmlFor="name" className="form-label">Nome:</label>
									    <input type="text" name="name" className="form-control" id="name" {...register("name")} />
									    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
									</Form.Group>
								</Col>

								<Col sm="3">
									<Form.Group>
									    <label htmlFor="hour_open" className="form-label">Horário de abertura:</label>
									    <InputMask mask="99:99" placeholder="00:00" value={hourOpen} onChange={onChangeHourOpen} name="hour_open" className="form-control" id="hour_open" />
									    <span className="error-message">{errors?.hour_open ? errors.hour_open[0] : null}</span>
									</Form.Group>
								</Col>

								<Col sm="3">
									<Form.Group>
									    <label htmlFor="hour_close" className="form-label">Horário de fechamento:</label>
									    <InputMask mask="99:99" placeholder="00:00" value={hourClose} onChange={onChangeHourClose} name="hour_close" className="form-control" id="hour_close" />
									    <span className="error-message">{errors?.hour_close ? errors.hour_close[0] : null}</span>
									</Form.Group>
								</Col>
							</Row>

							<Row className="mb-3">
								<Col sm="4">
									<Form.Group>
									    <label htmlFor="whatsapp" className="form-label">Whatsapp:</label>
									    <InputMask mask="+55 (99) 99999 - 9999" placeholder="+55 (00) 00000 - 0000" value={whatsapp} onChange={onChangeWhatsapp} name="whatsapp" className="form-control" id="whatsapp" />
									    <span className="error-message">{errors?.whatsapp ? errors.whatsapp[0] : null}</span>
									</Form.Group>
								</Col>

                				<Col sm="4">
									<Form.Group>
									    <label htmlFor="facebook" className="form-label">Facebook:</label>
									    <input type="text" name="facebook" className="form-control" id="facebook" {...register("facebook")} />
									    <span className="error-message">{errors?.facebook ? errors.facebook[0] : null}</span>
									</Form.Group>
								</Col>

								<Col sm="4">
									<Form.Group>
									    <label htmlFor="instagram" className="form-label">Instagram:</label>
									    <input type="text" name="instagram" className="form-control" id="instagram" {...register("instagram")} />
									    <span className="error-message">{errors?.instagram ? errors.instagram[0] : null}</span>
									</Form.Group>
								</Col>
							</Row>

							<Row className="mb-3">
								<Col sm="4">
									<Form.Group>
									    <label className="form-label">Dias de abertura:</label>
									    <Form.Check type="checkbox" label="Domingo"  id="open_sunday" {...register("open_sunday")} />
									    <Form.Check type="checkbox" label="Segunda-feira"  id="open_monday" {...register("open_monday")} />
									    <Form.Check type="checkbox" label="Tersa-feira"  id="open_tuesday" {...register("open_tuesday")} />
									    <Form.Check type="checkbox" label="Quarta-feira"  id="open_wednesday" {...register("open_wednesday")} />
									    <Form.Check type="checkbox" label="Quinta-feira"  id="open_thursday" {...register("open_thursday")} />
									    <Form.Check type="checkbox" label="Sexta-feira"  id="open_friday" {...register("open_friday")} />
									    <Form.Check type="checkbox" label="Sabado"  id="open_saturday" {...register("open_saturday")} />
									</Form.Group>
								</Col>
							</Row>


							<Row className="mb-4">
						    	<Col>
						    		<Button type="submit" className="btn" style={configDefault?.theme?.prymaryBackground}>Salvar</Button>
						    	</Col>
						    </Row>
                		</Form>
	                </>
	            )}
            </Can>

		</>
	);
}

export default ConfigDataCompany;