import React, {useState, useEffect, useContext} from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BiPencil, BiTrash, BiShowAlt} from "react-icons/bi";
import Header from '../Header/index.jsx';
import apiService from '../../../../../services/api.js';
import Loading from '../../../../../components/Loading/index.jsx';
import Pagination from '../../../../../components/Pagination/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../../services/actionsAppService.jsx';
import Context from "../../../../../Context";

const ListProposalStore = props => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('store-proposal');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('store-proposal');
	const [loading, setLoading] = useState(false);
	const [qtdPages, setQtdPages] = useState(1);
	const api = apiService.get();
	const history = useHistory();
	let valuePrev = localStorage.getItem('searchValuePrev');
	const { id } = useParams();

	const [storeProposals, setStoreProposals] = useState([]);
	const [proposalsProducts, setProposalsProducts] = useState([]);
	const [modalProductsShow, setModalProductsShow] = useState(false);
	
	const onHideModalProposalProducts = () => {
		setModalProductsShow(false);
	}

	const initProposals = () => {
		//api/${endpoint}?page=${offset}
		api.get(`api/store-proposal/by-solicitation/${props?.solicitationId}`)
		.then(resp => {
			const dataStoreProposals = [];

			resp.data.data.storeProposals.forEach(function(item, index){
				item.store_object = JSON.parse(item?.store_object);
				item.sub_total_price = item?.sub_total_price/100;
				item.ship_cost = item?.ship_cost/100;
				item.final_price = item?.final_price/100;
				dataStoreProposals.push(item);
			});

			setStoreProposals(dataStoreProposals);
			//setQtdPages(resp.data.data.pagination.pages);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			//setErrors(error.message)
			verifyError(error);
		})
	}

	const showModalProposalsProducts = (proposalsProducts) => {
		const dataProposalsProducts = [];

		proposalsProducts.forEach(function(item, index){
			item.store_product_object = JSON.parse(item?.store_product_object);
			item.price = item?.price/100;
			dataProposalsProducts.push(item);
		});

		setProposalsProducts(dataProposalsProducts);
		
		console.log(dataProposalsProducts);
		setModalProductsShow(true);
	}

	const acceptProposal = (proposalId, solicitationId) => {
		let data = {
			proposal_id: proposalId,
			solicitation_id: solicitationId,
		};
		api.post(`api/solicitation/accept-proposal`, data)
		.then(resp => {
			props.setModalProposalsShow(false);
			showNotify('success', 'Proposta aprovada com sucesso!');
			setLoading(false);
		})
		.catch(error => {
			showNotify('error', 'Ops, ocorreu algum erro!');
			//setErrors(error.message)
			verifyError(error);
		})
	}

	const refuseProposal = (proposalId, solicitationId) => {
		let data = {
			proposal_id: proposalId,
			solicitation_id: solicitationId,
		};
		api.post(`api/solicitation/refuse-proposal`, data)
		.then(resp => {
			props.setModalProposalsShow(false);
			showNotify('success', 'Proposta cancelada com sucesso!');
			setLoading(false);
		})
		.catch(error => {
			showNotify('error', 'Ops, ocorreu algum erro!');
			//setErrors(error.message)
			verifyError(error);
		})
	}

	useEffect(() => {
		initProposals();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [/*column, value/*, offset*/])


	return (
		<>
			<div className="area-crud">

				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-view`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Lista de propostas</h5>

					{ (loading) ? (<Loading />) : (

						(storeProposals?.length === 0) ? (
		                    <label>Nenhum registro encontrado...</label>
		                ) : (
		                	<div>
			                    <Table striped bordered hover>
									<thead>
										<tr>
											<th style={configDefault?.theme?.prymaryBackground}>Status</th>
											<th style={configDefault?.theme?.prymaryBackground}>Loja</th>
											<th style={configDefault?.theme?.prymaryBackground}>Empresa</th>
											<th style={configDefault?.theme?.prymaryBackground}>Subtotal</th>
											<th style={configDefault?.theme?.prymaryBackground}>Frete</th>
											<th style={configDefault?.theme?.prymaryBackground}>Total</th>
											<th style={configDefault?.theme?.prymaryBackground}>Ações</th>
										</tr>
									</thead>
									<tbody>
										{storeProposals?.map((item) => (
											<tr key={item.id}>
												<td>{item.status}</td>
												<td>{item?.store_object?.name}</td>
												<td>{item?.tenant?.name}</td>
												<td>{item?.sub_total_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
												<td>{item?.ship_cost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
												<td>{item?.final_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
												
												<td>
													{item.status !== 'aprovada' ?
														<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={() => acceptProposal(item?.id, item?.solicitation_id)}>
															aceitar
														</Button>
														:
														<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={() => refuseProposal(item?.id, item?.solicitation_id)}>
															recusar
														</Button>
													}

													<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={() => showModalProposalsProducts(item?.store_proposal_products)}>
														produtos
													</Button>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
								{/*<Pagination
									qtdPages={qtdPages}
									offset={offset}
									setOffset={setOffset}
								/>*/}

							</div>
		                )
		            )}
	            </Can>
	            
			</div>

			<Modal
                show={modalProductsShow}
                fullscreen={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
					
					<div className="area-crud">

						<h5 className="mb-3">Lista de produtos da proposta</h5>

						{ (loading) ? (<Loading />) : (

							(proposalsProducts?.length === 0) ? (
			                    <label>Nenhum registro encontrado...</label>
			                ) : (
			                	<div>
				                    <Table striped bordered hover>
										<thead>
											<tr>
												<th style={configDefault?.theme?.prymaryBackground}>Produto</th>
												<th style={configDefault?.theme?.prymaryBackground}>quantidade</th>
												<th style={configDefault?.theme?.prymaryBackground}>Preço</th>
												<th style={configDefault?.theme?.prymaryBackground}>Ações</th>
											</tr>
										</thead>
										<tbody>
											{proposalsProducts?.map((item) => (
												<tr key={item.id}>
													<td>{item?.store_product_object?.product?.name}</td>
													<td>{item?.quantity}</td>
													<td>{item?.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
													<td>
													-
													</td>
												</tr>
											))}
										</tbody>
									</Table>
									{/*<Pagination
										qtdPages={qtdPages}
										offset={offset}
										setOffset={setOffset}
									/>*/}

								</div>
			                )
			            )}
					</div>
                </Modal.Body>

                <Modal.Footer>
                    <Button style={configDefault?.theme?.prymaryBackground} onClick={onHideModalProposalProducts}>Fechar</Button>
                </Modal.Footer>
            </Modal>
		</>
	);
};

export default ListProposalStore;