import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Table } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";

const View = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('batch-product');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('batch-product');
	const api = apiService.get()
	const [batchProduct, setBatchProduct] = useState([]);
	const [loading, setLoading] = useState(false);

	const {
	    params: { id }
	} = match;
	
	const view = () => {
		setLoading(true);
	    api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			setBatchProduct(resp.data.data.batchProduct);
			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}

	
	useEffect(() => {
		view()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-view`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Dados do estoque</h5>

					{ (loading) ? (<Loading />) : (
						<Table striped bordered hover>
							<thead>
								<tr>
									<th style={configDefault?.theme?.prymaryBackground}>Coluna</th>
									<th style={configDefault?.theme?.prymaryBackground}>Valor</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Id</td>
									<td>{batchProduct?.id}</td>
								</tr>
								<tr>
									<td>Número do lote</td>
									<td>{batchProduct?.batch_number}</td>
								</tr>
								<tr>
									<td>Quantidade</td>
									<td>{batchProduct?.quantity}</td>
								</tr>
								<tr>
									<td>Tipo de estoque</td>
									<td>{batchProduct?.type_batch}</td>
								</tr>
								<tr>
									<td>Data de fabricação</td>
									<td>{batchProduct?.date_fabrication}</td>
								</tr>
								<tr>
									<td>Data de validade</td>
									<td>{batchProduct?.date_validate}</td>
								</tr>
								<tr>
									<td>Id do produto na loja</td>
									<td>{batchProduct?.product_store_id}</td>
								</tr>
								<tr>
									<td>Unidade de medida</td>
									<td>{batchProduct?.unit_measurement?.sigle}</td>
								</tr>
								<tr>
									<td>Marca</td>
									<td>{batchProduct?.brand?.name}</td>
								</tr>
							</tbody>
						</Table>
					)}
				</Can>
				
			</div>
		</>
	);
};

export default View;