import React from 'react';
import { BiX } from "react-icons/bi";
import ImgProductDefault from '../../../../assets/images/image-default-1.png';
import {useDispatch, useSelector} from 'react-redux';
import {plusQuantityProduct, plusQuantityPackages, decreaseQuantityProduct, removeProduct} from '../../../../store/modules/shoppingcart/actions';
import './style.css';
import SearchProductByCodeEAN from '../../SelectProducts/SearchProductByCodeEAN';

const ListProducts = ({showActionButtons = false}) => {
	const dispatch = useDispatch();
	const productsShoppingcart = useSelector(state => state.shoppingcart.products);

	const removeProductToShoppingCart = (idProduct) => {
		productsShoppingcart.forEach(function(el, i){
		    if(el?.dataProduct?.id === idProduct) {
		        dispatch(removeProduct(i));
		    }
		});
	}

	const plusQuantityProductToShoppingCart = (idProduct, typeItem) => {
		if(typeItem === 'product'){
			productsShoppingcart.forEach(function(el, i){
			    if(el?.dataProduct?.id === idProduct) {
			        dispatch(plusQuantityProduct(i));
			    }
			});
		}else{
			productsShoppingcart.forEach(function(el, i){
			    if(el?.dataProduct?.id === idProduct) {
			        dispatch(plusQuantityPackages(i));
			    }
			});
		}
	}

	const decreaseQuantityProductToShoppingCart = (idProduct) => {
		productsShoppingcart.forEach(function(el, i){
		    if(el?.dataProduct?.id === idProduct) {
		    	dispatch(decreaseQuantityProduct(i));
		    }
		});
	}

	const getQuantityProductToShoppingCart = (idProduct) => {
		let quantity = 0;
		productsShoppingcart.forEach(function(el, i){
		    if(el?.dataProduct?.id === idProduct)
		        quantity = el?.quantity;
		});
		return quantity;
	}

	const ImageProduct = ({item}) => {
		console.log(item);
		if(item?.type === 'product'){
			return (
				<div className="col-2">
					{item?.dataProduct?.product?.image !== null ?
						<img alt="" className="img-product" src={item?.dataProduct?.product?.image} />
						:
						<img alt="" className="img-product" src={ImgProductDefault} />
					}
				</div>
			);
		}else{
			return (
				<div className="col-2">
					{item?.dataProduct?.product?.image == null ?
						<img alt="" className="img-product" src={ImgProductDefault} />
						:
						<img alt="" className="img-product" src={item?.dataProduct?.product?.image} />
					}
				</div>
			);
		}
	}

	return (
		<>
			<div className="area-list-products">
				<p className="info-list-products">Adicione produtos ao carrinho passando o<br/>código de barras no scan.</p>
				<SearchProductByCodeEAN showShoppingButton={false}></SearchProductByCodeEAN>
				<ul className="list-products-purchased">
					{productsShoppingcart?.map((item, index) => (
						<li key={index}>
							<div className="row">
								<ImageProduct item={item} />

								<div className="col-10">
									<div className="row">
										<div className="col-10">
											<label className="name-product">
												{item?.type === 'product' ? 
													item?.dataProduct?.product.name
													:
													item?.dataProduct?.product.name
												}
											</label>
										</div>
										<div className="col-2">
											{/* TODO: ADD ADMIN SEC CODE TO REMOVE */}
											<button className="btn-remove-product-list" onClick={() => removeProductToShoppingCart(item?.dataProduct?.id)}><BiX/></button>
										</div>
									</div>

									<div className="row">
										<div className="col-6">
											<label className="price-product">{(item?.dataProduct?.price/100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
										</div>
										<div className="col-6">
											<div className="area-qtd-products">
												{showActionButtons && <label>Qtd.</label>}
												{showActionButtons && <button onClick={() => decreaseQuantityProductToShoppingCart(item?.dataProduct?.id)}>-</button>}
												<span>{getQuantityProductToShoppingCart(item?.dataProduct?.id)}</span>
												{showActionButtons && <button onClick={() => plusQuantityProductToShoppingCart(item?.dataProduct?.id, item?.type)}>+</button>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
					))}

				</ul>
			</div>
		</>
	);
};

export default ListProducts;