import React, {useState, useContext} from 'react';
import { BiSearch } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import { Can } from "react-access-level";
import { useHistory } from "react-router-dom";
import Context from "../../../../../Context";

const Header = () => {
    
	return (
		<>
			<h2 className="title-endpoint">Propostas</h2>
        </>
	);
};

export default Header;