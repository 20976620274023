import produce from 'immer';
import types from './types'
import { showNotify } from '../../../services/actionsAppService.jsx';


function shoppingcart (state = {products:[]}, action) {
	switch(action.type){
		case types.SET_PRODUCT: {
			return produce(state, (draft) => {
				draft.products.push(action.product);
			})
		}

		case types.REMOVE_PRODUCT: {
			return produce(state, (draft) => {
				draft.products = draft.products.filter((data, index) => index !== action.index);
			})
		}

		case types.CHANGE_QUANTITY_PRODUCT: {

			return produce(state, (draft) => {
				if(action.data.value === 0){
					draft.products = draft.products.filter((data, index) => index !== action.data.index);
				}else{
					if(action.data.value <= draft.products[action.data.index].dataProduct.quantity){
						draft.products[action.data.index].quantity = action.data.value;
					}else{
						showNotify('error', 'Quantidade indisponível!');
					}
				}
			})
		}

		case types.CHANGE_QUANTITY_PACKAGES: {
			return produce(state, (draft) => {
				if(action.data.value === 0){
					draft.products = draft.products.filter((data, index) => index !== action.data.index);
				}else{
					if(action.data.value <= draft.products[action.data.index].dataProduct.quantity_packages){
						draft.products[action.data.index].quantity = action.data.value;
					}else{
						showNotify('error', 'Quantidade indisponível!');
					}
				}
			})
		}

		case types.PLUS_QUANTITY_PRODUCT: {
			return produce(state, (draft) => {
				if(draft.products[action.index].quantity < draft.products[action.index].dataProduct.quantity){
					draft.products[action.index].quantity += 1;
				}
			})
		}

		case types.PLUS_QUANTITY_PACKAGES: {
			return produce(state, (draft) => {
				if(draft.products[action.index].quantity < draft.products[action.index].dataProduct.quantity_packages){
					draft.products[action.index].quantity += 1;
				}
			})
		}

		case types.DECREASE_QUANTITY_PRODUCT: {
			return produce(state, (draft) => {
				if(draft.products[action.index].quantity <= 1)
					draft.products = draft.products.filter((data, index) => index !== action.index);
				else
					draft.products[action.index].quantity -= 1;
			})
		}

		default:
			return state;
	}
}

export default shoppingcart;