import React from 'react';

import './style.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from "../../../components/Route/privateRoute.jsx";
import Header from '../Header/index.jsx';
import Sidebar from '../Sidebar/index.jsx';

import Overview from '../../../pages/Dashboard/Overview/View/index.jsx';

import ListUser from '../../../pages/Dashboard/User/List/index.jsx';
import RegisterEditUser from '../../../pages/Dashboard/User/RegisterEdit/index.jsx';
import ViewUser from '../../../pages/Dashboard/User/View/index.jsx';

import ListStore from '../../../pages/Dashboard/Store/List/index.jsx';
import RegisterEditStore from '../../../pages/Dashboard/Store/RegisterEdit/index.jsx';
import ViewStore from '../../../pages/Dashboard/Store/View/index.jsx';

import ListToten from '../../../pages/Dashboard/Toten/List/index.jsx';
import RegisterEditToten from '../../../pages/Dashboard/Toten/RegisterEdit/index.jsx';
import ViewToten from '../../../pages/Dashboard/Toten/View/index.jsx';

import ListPurchase from '../../../pages/Dashboard/Purchase/List/index.jsx';
import ViewPurchase from '../../../pages/Dashboard/Purchase/View/index.jsx';

import ListProduct from '../../../pages/Dashboard/Product/List/index.jsx';
import RegisterEditProduct from '../../../pages/Dashboard/Product/RegisterEdit/index.jsx';
import ViewProduct from '../../../pages/Dashboard/Product/View/index.jsx';

import ListBatchProduct from '../../../pages/Dashboard/BatchProduct/List/index.jsx';
import RegisterEditBatchProduct from '../../../pages/Dashboard/BatchProduct/RegisterEdit/index.jsx';
import ViewBatchProduct from '../../../pages/Dashboard/BatchProduct/View/index.jsx';

import ListInventoryStore from '../../../pages/Dashboard/InventoryStore/List/index.jsx';
import ViewInventoryStore from '../../../pages/Dashboard/InventoryStore/View/index.jsx';

import ListStoreProduct from '../../../pages/Dashboard/Store/RelationStoreProduct/List/index.jsx';
import RegisterEditStoreProduct from '../../../pages/Dashboard/Store/RelationStoreProduct/RegisterEdit/index.jsx';
import ViewStoreProduct from '../../../pages/Dashboard/Store/RelationStoreProduct/View/index.jsx';

import ListTax from '../../../pages/Dashboard/Tax/List/index.jsx';
import RegisterEditTax from '../../../pages/Dashboard/Tax/RegisterEdit/index.jsx';
import ViewTax from '../../../pages/Dashboard/Tax/View/index.jsx';

import ListPackageProduct from '../../../pages/Dashboard/PackageProduct/List/index.jsx';
import RegisterEditPackageProduct from '../../../pages/Dashboard/PackageProduct/RegisterEdit/index.jsx';
import ViewPackageProduct from '../../../pages/Dashboard/PackageProduct/View/index.jsx';

import ListCategory from '../../../pages/Dashboard/Category/List/index.jsx';
import RegisterEditCategory from '../../../pages/Dashboard/Category/RegisterEdit/index.jsx';
import ViewCategory from '../../../pages/Dashboard/Category/View/index.jsx';

import ListSolicitation from '../../../pages/Dashboard/Solicitation/List/index.jsx';
import RegisterEditSolicitation from '../../../pages/Dashboard/Solicitation/RegisterEdit/index.jsx';
import RegisterEditProposal from '../../../pages/Dashboard/Solicitation/OwnerProposalStore/RegisterEdit/index.jsx';
import ViewSolicitation from '../../../pages/Dashboard/Solicitation/View/index.jsx';

import ListCustomer from '../../../pages/Dashboard/Customer/List/index.jsx';
import RegisterEditCustomer from '../../../pages/Dashboard/Customer/RegisterEdit/index.jsx';
import ViewCustomer from '../../../pages/Dashboard/Customer/View/index.jsx';

import ListRole from '../../../pages/Dashboard/AccessControl/List/index.jsx';
import RegisterEditRole from '../../../pages/Dashboard/AccessControl/RegisterEdit/index.jsx';
import ViewRole from '../../../pages/Dashboard/AccessControl/View/index.jsx';

import AdminConfigOverview from '../../../pages/Dashboard/AdminConfig/Overview/index.jsx';

import ViewProfile from '../../../pages/Dashboard/Profile/View/index.jsx';

import ErrorPage from '../../../pages/Error/index.jsx';

const Main = () => {
	return (
		<>
		    <div className="area-dashboard">
		    	<BrowserRouter>
		    		<Sidebar></Sidebar>

		    		<div className="area-main">
		    			<Header></Header>
			    		<Switch>
			    			<PrivateRoute path="/dashboard" exact component={Overview} />

			    			<PrivateRoute path="/dashboard/user/list" component={ListUser} />
			    			<PrivateRoute path="/dashboard/user/register" component={RegisterEditUser} />
			    			<PrivateRoute path="/dashboard/user/edit/:id" component={RegisterEditUser} />
			    			<PrivateRoute path="/dashboard/user/view/:id" component={ViewUser} />
			    			<PrivateRoute path="/dashboard/user/search/:column/:value" component={ListUser} />

			    			<PrivateRoute path="/dashboard/store/list" component={ListStore} />
			    			<PrivateRoute path="/dashboard/store/register" component={RegisterEditStore} />
			    			<PrivateRoute path="/dashboard/store/edit/:id" component={RegisterEditStore} />
			    			<PrivateRoute path="/dashboard/store/view/:id" component={ViewStore} />
			    			<PrivateRoute path="/dashboard/store/search/:column/:value" component={ListStore} />

			    			<PrivateRoute path="/dashboard/toten/list" component={ListToten} />
			    			<PrivateRoute path="/dashboard/toten/register" component={RegisterEditToten} />
			    			<PrivateRoute path="/dashboard/toten/edit/:id" component={RegisterEditToten} />
			    			<PrivateRoute path="/dashboard/toten/view/:id" component={ViewToten} />
			    			<PrivateRoute path="/dashboard/toten/search/:column/:value" component={ListToten} />

			    			<PrivateRoute path="/dashboard/purchase/list" component={ListPurchase} />
			    			<PrivateRoute path="/dashboard/purchase/view/:id" component={ViewPurchase} />
			    			<PrivateRoute path="/dashboard/purchase/search/:column/:value" component={ListPurchase} />

			    			<PrivateRoute path="/dashboard/product/list" component={ListProduct} />
			    			<PrivateRoute path="/dashboard/product/register" component={RegisterEditProduct} />
			    			<PrivateRoute path="/dashboard/product/edit/:id" component={RegisterEditProduct} />
			    			<PrivateRoute path="/dashboard/product/view/:id" component={ViewProduct} />
			    			<PrivateRoute path="/dashboard/product/search/:column/:value" component={ListProduct} />

			    			<PrivateRoute path="/dashboard/batch-product/list" component={ListBatchProduct} />
			    			<PrivateRoute path="/dashboard/batch-product/register" component={RegisterEditBatchProduct} />
			    			<PrivateRoute path="/dashboard/batch-product/view/:id" component={ViewBatchProduct} />
			    			<PrivateRoute path="/dashboard/batch-product/search/:column/:value" component={ListBatchProduct} />

			    			<PrivateRoute path="/dashboard/inventory-store/list" component={ListInventoryStore} />
			    			<PrivateRoute path="/dashboard/inventory-store/view/:id" component={ViewInventoryStore} />
			    			<PrivateRoute path="/dashboard/inventory-store/search/:column/:value" component={ListInventoryStore} />

			    			<PrivateRoute path="/dashboard/store-product/:storeId/list" component={ListStoreProduct} />
			    			<PrivateRoute path="/dashboard/store-product/:storeId/register" component={RegisterEditStoreProduct} />
			    			<PrivateRoute path="/dashboard/store-product/:storeId/edit/:id" component={RegisterEditStoreProduct} />
			    			<PrivateRoute path="/dashboard/store-product/:storeId/view/:id" component={ViewStoreProduct} />
			    			<PrivateRoute path="/dashboard/store-product/:storeId/search/:column/:value" component={ListStoreProduct} />

			    			<PrivateRoute path="/dashboard/tax/list" component={ListTax} />
			    			<PrivateRoute path="/dashboard/tax/register" component={RegisterEditTax} />
			    			<PrivateRoute path="/dashboard/tax/edit/:id" component={RegisterEditTax} />
			    			<PrivateRoute path="/dashboard/tax/view/:id" component={ViewTax} />
			    			<PrivateRoute path="/dashboard/tax/search/:column/:value" component={ListTax} />

			    			<PrivateRoute path="/dashboard/package-product/list" component={ListPackageProduct} />
			    			<PrivateRoute path="/dashboard/package-product/register" component={RegisterEditPackageProduct} />
			    			<PrivateRoute path="/dashboard/package-product/edit/:id" component={RegisterEditPackageProduct} />
			    			<PrivateRoute path="/dashboard/package-product/view/:id" component={ViewPackageProduct} />
			    			<PrivateRoute path="/dashboard/package-product/search/:column/:value" component={ListPackageProduct} />

			    			<PrivateRoute path="/dashboard/category/list" component={ListCategory} />
			    			<PrivateRoute path="/dashboard/category/register" component={RegisterEditCategory} />
			    			<PrivateRoute path="/dashboard/category/edit/:id" component={RegisterEditCategory} />
			    			<PrivateRoute path="/dashboard/category/view/:id" component={ViewCategory} />
			    			<PrivateRoute path="/dashboard/category/search/:column/:value" component={ListCategory} />

			    			<PrivateRoute path="/dashboard/solicitation/list" component={ListSolicitation} />
			    			<PrivateRoute path="/dashboard/solicitation/register" component={RegisterEditSolicitation} />
			    			<PrivateRoute path="/dashboard/solicitation/register-proposal/:solicitationId" component={RegisterEditProposal} />
			    			<PrivateRoute path="/dashboard/solicitation/edit/:id" component={RegisterEditSolicitation} />
			    			<PrivateRoute path="/dashboard/solicitation/view/:id" component={ViewSolicitation} />
			    			<PrivateRoute path="/dashboard/solicitation/search/:column/:value" component={ListSolicitation} />

							<PrivateRoute path="/dashboard/customer/list" component={ListCustomer} />
			    			<PrivateRoute path="/dashboard/customer/register" component={RegisterEditCustomer} />
			    			<PrivateRoute path="/dashboard/customer/edit/:id" component={RegisterEditCustomer} />
			    			<PrivateRoute path="/dashboard/customer/view/:id" component={ViewCustomer} />
			    			<PrivateRoute path="/dashboard/customer/search/:column/:value" component={ListCustomer} />

			    			<PrivateRoute path="/dashboard/access-control/list" component={ListRole} />
			    			<PrivateRoute path="/dashboard/access-control/register" component={RegisterEditRole} />
			    			<PrivateRoute path="/dashboard/access-control/edit/:id" component={RegisterEditRole} />
			    			<PrivateRoute path="/dashboard/access-control/view/:id" component={ViewRole} />
			    			<PrivateRoute path="/dashboard/access-control/search/:column/:value" component={ListRole} />

			    			<PrivateRoute path="/dashboard/admin/config" component={AdminConfigOverview} />

			    			<PrivateRoute path="/dashboard/profile/view" component={ViewProfile} />

			    			<PrivateRoute path="/error-page/:code" component={ErrorPage} />
							<Route component={ErrorPage} />

			    		</Switch>
		    		</div>
		    	</BrowserRouter>
		    </div>
		</>
	);
};

export default Main;