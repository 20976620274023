import React from 'react';
import { Link } from "react-router-dom";
import './style.css';
import {useSelector} from 'react-redux';

const TotalValue = () => {
	const productsShoppingcart = useSelector(state => state.shoppingcart.products);

	const getDiscount = () => {
		return 0;
	}

	const calcDiscountIntervalProduct = (quantity, price, listDiscounts) => {
		
		let discount = 0;
		listDiscounts?.forEach(function(el, i){
		    if(quantity >= el.min_quantity_products && quantity <= el.max_quantity_products){
		    	discount = parseFloat(el.discount_value) * price * quantity;
		    }
		});

		return discount;
	}

	const calcSubTotalPurchase = () => {
		let total = 0;
		productsShoppingcart.forEach(function(el, i){
		    total += ((el?.dataProduct?.price/100) * el?.quantity) - calcDiscountIntervalProduct(el?.quantity, el?.dataProduct?.price/100, el?.dataProduct?.interval_discounts);
		});
		return total;
	}

	const calcTotalPurchase = () => {
		return calcSubTotalPurchase() - getDiscount();
	}

	return (
		<div className="area-purchase-shopping-cart">
			<div className="info-descont">
				<label>Desconto:</label>
				<span>R$ 0,00</span>
			</div>

			<div className="info-subtotal">
				<label>Subtotal:</label>
				<span>{calcSubTotalPurchase().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
			</div>

			<div className="value-total">
				<label>Valor final:</label>
				<span>{calcTotalPurchase().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
			</div>

			<div className="area-actions-purchase">
				{/* <button>CPF NA NOTA</button> */}
				{/* <button>CUPOM</button> */}
				<Link to='/panel-toten/payment'><button>PAGAR</button></Link>
			</div>
		</div>
	);
};

export default TotalValue;