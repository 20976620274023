import React, {useState, useEffect} from "react";
import { ReactACLProvider } from "react-access-level";
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import apiService from './services/api.js';
import { showNotify, verifyError } from './services/actionsAppService.jsx';
import Context from './Context';

const App = () => {

  const api = apiService.get();
  const [configDefault, setConfigDefault] = useState({});

  const initConfigSystem = () => {
    api.get(`api/config`)
    .then(resp => {
      let config = resp.data.data.config;
      let configDefaultTemp = {
        theme:{
          sidebarFontColor: {
            color: config.font_color_sidebar,
          },
          sidebarBgColor: {
            backgroundColor: config.bg_sidebar,
          },
          prymaryColor: {
            color: config.font_primary_color,
          },
          prymaryBackground: {
            backgroundColor: config.bg_primary_color,
            border: 'none',
          },
        },
        mapDefault: {
          zoom: config.zoom_default,
          lat: config.lat_default,
          longi: config.longi_default,
        }
      };
      setConfigDefault(configDefaultTemp);
    })
    .catch(error =>{
      showNotify('Error', 'Ops, ocorreu algum erro!');
      verifyError(error);
    })
  }


  useEffect(() => {
    initConfigSystem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  

  function getInitialUser() {
    return false;
  }

  function getInitialRoles() {
    let dataUser = JSON.parse(localStorage.getItem('dataUserSSSystem'));
    
    let permissionsEnd = [];

    if(dataUser != null){
      let permissionsTemp = [];

      if(dataUser?.role.length > 0)
        permissionsTemp = [...dataUser?.role[0]?.permissions];
      
      if(dataUser.permissions.length > 0)
        permissionsTemp = [...permissionsTemp, ...dataUser.permissions];

      

      permissionsTemp.forEach(function(item){
        let endPoint = item.name.substr(0, item.name.lastIndexOf('-'));
        let permission = item.name;
        permissionsEnd.push(`${endPoint}:${permission}`); 
      });
    }
    
    return permissionsEnd;
  }


  return (
    
    <ReactACLProvider extractInitialUser={getInitialUser} extractInitialRoles={getInitialRoles}>
      <Context.Provider value={[configDefault, setConfigDefault]}>
        <Routes />
      </Context.Provider>
    </ReactACLProvider>

  );
}


export default App;
