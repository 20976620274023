import React, {useState, useEffect, useContext} from 'react';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BiPencil, BiTrash, BiShowAlt, BiXCircle} from "react-icons/bi";
import Header from '../Header/index.jsx';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import Pagination from '../../../../components/Pagination/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";

const List = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(1);
	const [qtdPages, setQtdPages] = useState(1);
	const api = apiService.get()
	let valuePrev = localStorage.getItem('searchValuePrev');
	const {
	    params: { column, value }
	} = match;

	const listUsers = () => {
		setLoading(true);
		let queryApi = '';
		if(typeof column !== "undefined" && typeof value !== "undefined"){
			if(valuePrev !== value){
				setOffset(1)
				localStorage.setItem('searchValuePrev', value);
			}
			queryApi = `api/user/search/${column}/${value}?page=${offset}`;
		}else{
			queryApi = `api/user?page=${offset}`;
		}
		
		api.get(queryApi)
		.then(resp => {
			setUsers(resp.data.data.users);
			setQtdPages(resp.data.data.pagination.pages);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}
	
	const deleteUser = (id, e) => {
		setLoading(true);
		api.delete(`api/user/${id}`)
		.then(resp => {
			showNotify('success', 'Usuário deletado com sucesso!');
			listUsers();
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	const removeRole = (idUser, idRole, e) => {
		setLoading(true);
		api.delete(`api/user/remove-role/${idUser}/${idRole}`)
		.then(resp => {
			showNotify('success', 'Função removida com sucesso!');
			listUsers()
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	const removePermission = (idUser, idPermission, e) => {
		setLoading(true);
		api.delete(`api/user/remove-permission/${idUser}/${idPermission}`)
		.then(resp => {
			showNotify('success', 'Permissão removida com sucesso!');
			listUsers()
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	useEffect(() => {
		listUsers()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [column, value, offset])


	return (
		<>
			<div className="area-crud">

				<Header></Header>

				<Can 
					resource="user"
					authority="user-view"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Lista de usuários</h5>

					{ (loading) ? (<Loading />) : (

						(users?.length === 0) ? (
		                    <label>Nenhum registro encontrado...</label>
		                ) : (
		                	<div>
			                    <Table striped bordered hover>
									<thead>
										<tr>
											<th style={configDefault?.theme?.prymaryBackground}>Nome</th>
											<th style={configDefault?.theme?.prymaryBackground}>Email</th>
											<th style={configDefault?.theme?.prymaryBackground}>Função</th>
											<th style={configDefault?.theme?.prymaryBackground}>Permissões</th>
											<th style={configDefault?.theme?.prymaryBackground}>Ações</th>
										</tr>
									</thead>
									<tbody>
										{users?.map((user) => (
											<tr key={user.id}>
												<td>{user.name}</td>
												<td>{user.email}</td>
												<td>
													{user.role.length > 0 ?
														(<Button variant="dark" className="button-remove-tag" style={configDefault?.theme?.prymaryBackground}>
															{user.role[0]?.name} <i onClick={(e) => {if (window.confirm('Você realmente deseja remover esta função do usuário?')) removeRole(user.id, user.role[0].id, e)}}><BiXCircle /></i>
														</Button>) : <label>-</label>
													}
												</td>
												<td>
													{user.permissions?.map((permission) => (
														<Button variant="dark" className="button-remove-tag" style={configDefault?.theme?.prymaryBackground}>{permission.name} <i onClick={(e) => {if (window.confirm('Você realmente deseja remover esta permissão do usuário?')) removePermission(user.id, permission.id, e)}}><BiXCircle /></i></Button>
													))}
												</td>
												<td>
													<Can resource="user" authority="user-view">
														<Link to={`/dashboard/user/view/${user.id}`} className="btn btn-dark" style={configDefault?.theme?.prymaryBackground}>
															<BiShowAlt />
														</Link>
													</Can>
													<Can resource="user" authority="user-update">
														<Link to={`/dashboard/user/edit/${user.id}`} className="btn btn-dark" style={configDefault?.theme?.prymaryBackground}>
															<BiPencil />
														</Link>
													</Can>
													<Can resource="user" authority="user-delete">
														<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={(e) => {if (window.confirm('Você realmente deseja excluir este registro?')) deleteUser(user.id, e)}}>
															<BiTrash />
														</Button>
													</Can>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
								<Pagination
									qtdPages={qtdPages}
									offset={offset}
									setOffset={setOffset}
								/>

							</div>
		                )
		            )}
	            </Can>
	            
			</div>
		</>
	);
};

export default List;