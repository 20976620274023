import React from 'react';
import Header from './Header/index.jsx';
import MethodsPayment from './MethodsPayment/index.jsx';
import TotalValue from './TotalValue/index.jsx';
import Footer from "../../../components/PanelToten/Footer/index.jsx";
import './style.css';

const Payment = () => {
	return (
		<div className="page-payment">
			<Header />
			<div className="container-self-checkout">
				<MethodsPayment />
				<TotalValue />
			</div>

			<Footer />
		</div>
	);
};

export default Payment;