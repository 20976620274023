import React, {useContext} from 'react';
import './style.css';
import { Dropdown } from 'react-bootstrap';
import { useHistory, Link } from "react-router-dom";
import Context from "../../../Context";

const Header = () => {
	const [configDefault] = useContext(Context);
	const history = useHistory();
	let user = JSON.parse(localStorage.getItem('dataUserSSSystem'));

	const logout = () => {
		localStorage.clear();
		window.location.reload();
	}

	const pagePanelToten = () => {
		window.open(`/panel-toten/login` , '_blank');
	}


	return (
		<>
			<header>
				<nav>
					<div>
						<Link onClick={pagePanelToten} className="btn btn-primary" to='#' style={configDefault?.theme?.prymaryBackground}>Painel do Totem</Link>
					</div>

					<div>
						<label className="welcome">Bem vindo: {user.name}</label>

						<Dropdown className="dropdown-nav">
							<Dropdown.Toggle id="dropdown-basic" style={configDefault?.theme?.prymaryBackground}>
							    SS System
							</Dropdown.Toggle>

							<Dropdown.Menu className="items-dropdown">

							    <Dropdown.Item>
							   		<Link to='/dashboard/profile/view'>Perfil</Link>
							   	</Dropdown.Item>
							    
							   	<Dropdown.Item>
							   		<Link to='/dashboard/admin/config/overview'>Configurações</Link>
							   	</Dropdown.Item>
							    
							  	<Dropdown.Item onClick={logout}>Sair</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</nav>
			</header>
		</>
	);
};

export default Header;