import React from 'react';
import './style.css';
import Main from '../../components/PanelToten/Main/index.jsx';
import { ToastContainer } from 'react-toastify';

const PanelToten = () => {

	return (
		<>
			<div className="area-toten-panel">
				<ToastContainer
					position="top-center"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<Main></Main>
			</div>
		</>
	);
};

export default PanelToten;