import produce from 'immer';
import types from './types'


function products (state = {products:[]}, action) {
	switch(action.type){
		case types.SET_PRODUCTS: {
			return produce(state, (draft) => {
				draft.products = action.products;
			})
		}

		default:
			return state;
	}
}

export default products;