import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";
import { NumericFormat } from 'react-number-format';

const RegisterEdit = props => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('package-product');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('package-product');

	const { id } = useParams();

	const api = apiService.get();
	const history = useHistory();
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setValue, getValues } = useForm();
	const [storeProducts, setStoreProducts] = useState([]);
	const [stores, setStores] = useState([]);
	const [storeId, setStoreId] = useState('');
	
	const onSubmit = data => {
		data.price = parseFloat(getValues('price').toString().replace(',', '.')) * 100;
		data.quantity_products = parseFloat(getValues('quantity_products'));
		if(id !== undefined)
			update(data);
		else
			create(data);
	}

	const create = data => {
		setLoading(true);
		
		api.post(`api/${endpoint}`, data)
		.then(resp => {
			showNotify('success', 'Pacote do produto registrado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const update = data => {
		setLoading(true);

		api.put(`api/${endpoint}/${id}`, data)
		.then(resp => {
			showNotify('success', 'Pacote do produto atualizado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors);
			setLoading(false);
			verifyError(error);
		})
	}

	const initStores = () => {
		api.get('api/store/all')
		.then(resp => {
			setStores(resp.data.data.stores);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const initStoreProducts = async (idStore = null) => {
		api.get(`api/store-product/by-store/${idStore}`)
		.then(resp => {
			setStoreProducts(resp.data.data.storeProducts);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const onChangeStore = (e) => {
		let idStore = e.target.value;
		initStoreProducts(idStore);
	}
	
	const initEndpoint = () => {
		setLoading(true);
		api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			const data = resp.data.data.packageProduct;
			setValue("name", data.name);
			setValue("quantity_products", data?.quantity_products);
			setValue("quantity_packages", data?.quantity_packages);
			setValue("price", data.price / 100);
			setValue("store_product_id", data.store_product_id);
			setStoreId(data?.product_store?.store?.id);
			
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	useEffect(() => {
		if(id !== undefined && storeId !== ''){
			initStoreProducts(storeId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storeId])

	useEffect(() => {
		initStores();

		if(id !== undefined){
			initEndpoint();
		}else{
			document.getElementById('form-register').reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-create`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de registro</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onSubmit)} className="form-register" id="form-register">
						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="quantity_products" className="form-label">Quantidade de produtos:<span className="req-span">*</span></label>
								    <NumericFormat displayType="input" name="quantity_products" className="form-control" id="quantity_products" onChange={(e) => setValue("quantity_products", e.target.value)} value={getValues('quantity_products')} />
								    <span className="error-message">{errors?.quantity_products ? errors.quantity_products[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="quantity_packages" className="form-label">Quantidade de pacotes:<span className="req-span">*</span></label>
								    <input type="number" name="quantity_packages" className="form-control" id="quantity_packages" {...register("quantity_packages")} />
								    <span className="error-message">{errors?.quantity_packages ? errors.quantity_packages[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<label htmlFor="price" className="form-label">Preço:<span className="req-span">*</span></label>
								<div className="input-group">
									<span className="input-group-text">R$</span>
									<NumericFormat
										value={getValues('price')}
										name="price"
										id="price"
										className="form-control"
									 	decimalScale={2}
									  	decimalSeparator=","
									  	fixedDecimalScale
									  	thousandSeparator="."
									  	onChange={(e) => setValue("price", e.target.value)}
									/>
								</div>
								<span className="error-message">{errors?.price ? errors.price[0] : null}</span>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="store_id" className="form-label">Loja:</label>
								    <select className="form-select" id="store_id" name="store_id" onChange={(e) => onChangeStore(e)}>
								    	<option value="">Selecionar</option>
							            {stores?.map((store, index) => (
								    		<option value={store?.id} selected={storeId === store?.id ? true : false} key={index}>{store?.name}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.store_id ? errors.store_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="store_product_id" className="form-label">Produto da loja:</label>
								    <select className="form-select" id="store_product_id" name="store_product_id" {...register("store_product_id")}>
								    	<option value="">Selecionar</option>
							            {storeProducts?.map((storeProduct, index) => (
								    		<option value={storeProduct.id} selected={getValues('store_product_id') === storeProduct?.id ? true : false} key={index}>{storeProduct?.product?.name}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.store_product_id ? errors.store_product_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn" style={configDefault?.theme?.prymaryBackground}>{id !== undefined ? 'Salvar' : 'Cadastrar'}</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default RegisterEdit;