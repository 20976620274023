import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";

const RegisterEdit = props => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('solicitation');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('solicitation');

	const { id } = useParams();

	const api = apiService.get();
	const history = useHistory();
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setValue } = useForm();
	const [stores, setStores] = useState([]);
	const [productsSelected, setProductsSelected] = useState([]);
	const [products, setProducts] = useState([]);
	
	const onSubmit = data => {
		if(id !== undefined)
			update(data);
		else
			create(data);
	}

	const create = data => {
		setLoading(true);
		data.products = productsSelected;

		api.post(`api/${endpoint}`, data)
		.then(resp => {
			showNotify('success', 'Solicitação registrada com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const update = data => {
		setLoading(true);

		api.put(`api/${endpoint}/${id}`, data)
		.then(resp => {
			showNotify('success', 'Solicitação atualizada com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors);
			setLoading(false);
			verifyError(error);
		})
	}

	const initStores = () => {
		api.get('api/store/all')
		.then(resp => {
			setStores(resp.data.data.stores);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const onChangeProducts = (e) => {
		let nameProduct = e.target.value;
		if(nameProduct.length >= 3){
			api.get(`api/product/search/name/${nameProduct}`)
			.then(resp => {
				const data = resp.data.data.products;
				setProducts(data);
				setLoading(false);
			})
			.catch(error =>{
				showNotify('error', 'Ops, ocorreu algum erro!');
				setErrors(error.message)
				verifyError(error);
			})
		}
	}

	const selectProduct = (product) => {
		if(productsSelected.findIndex(item => item.id === product.id) < 0){
			setProductsSelected([...productsSelected, {
				product_id: product?.id,
				name: product?.name,
				quantity: 1,
				unit_measurement_sigle: product?.unit_measurement?.sigle
			}]);
		}
	}

	const removeProduct = (productSelected) => {
		setProductsSelected([...productsSelected?.filter(item => item.id !== productSelected.id)]);
	}

	const onChangeQuantityProducts = (e, index) => {
		let array = productsSelected;
		array[index].quantity = e.target.value;
		setProductsSelected(array);
	}

	
	const initEndpoint = () => {
		setLoading(true);
		api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			const data = resp.data.data.tax;
			setValue("store_id", data.store_id);

			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	useEffect(() => {
		initStores();
		if(id !== undefined){
			initEndpoint();
		}else{
			document.getElementById('form-register').reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-create`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de registro</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onSubmit)} className="form-register" id="form-register">
						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="store_id" className="form-label">Loja:</label>
								    <select className="form-select" id="store_id" name="store_id" {...register("store_id")}>
								    	<option value="">Selecionar</option>
							            {stores?.map((store, index) => (
								    		<option value={store?.id} key={index}>{store?.name}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.store_id ? errors.store_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="name-product" className="form-label">Buscar produto:</label>
								    <input type="text" name="name_product" className="form-control" id="name-product" onChange={(e) => onChangeProducts(e)} />
								</Form.Group>
							</Col>
						</Row>
						
						<Row className="mb-3">
							<Col sm="6">
								<label className="form-label">Resultado da busca:</label>
								<ul className="list-group">
									{products?.map((product, index) => (
										<li className="list-group-item d-flex justify-content-between align-items-center">
									    	{product?.name} | {product?.unit_measurement?.sigle || '-'}
											<Button type="button" className="btn-sm" onClick={() => selectProduct(product)} style={configDefault?.theme?.prymaryBackground}>+</Button>
										</li>
									))}
								</ul>
							</Col>

							<Col sm="6">
								<label className="form-label">Produtos selecionados:</label>
								
								<ul className="list-group">
									{productsSelected?.map((productSelected, index) => (
										<li className="list-group-item d-flex justify-content-between align-items-center">
											<Col sm="6">
												<label>{productSelected?.name} | {productSelected?.unit_measurement_sigle || '-'}</label>
											</Col>

											<input type="number" placeholder="quantidade" className="form-control px-2 py-0" onChange={(e) => onChangeQuantityProducts(e, index)} />
											
											<Button type="button" className="btn-sm" onClick={() => removeProduct(productSelected)} style={configDefault?.theme?.prymaryBackground}>-</Button>
										</li>
									))}
								</ul>
								
							</Col>
						</Row>

					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn" style={configDefault?.theme?.prymaryBackground}>{id !== undefined ? 'Salvar' : 'Solicitar'}</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default RegisterEdit;