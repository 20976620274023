import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoginUserStore from '../../../pages/PanelToten/AuthUserStore/login.jsx';
import LogoutUserStore from '../../../pages/PanelToten/AuthUserStore/logout.jsx';
import Home from '../../../pages/PanelToten/Home/index.jsx';
import SelectProducts from '../../../pages/PanelToten/SelectProducts/index.jsx';
import ShoppingCart from '../../../pages/PanelToten/ShoppingCart/index.jsx';
import Payment from '../../../pages/PanelToten/Payment/index.jsx';
import FinishPayment from '../../../pages/PanelToten/FinishPayment/index.jsx';

import PrivateRoutePanelToten from "../../Route/privateRoutePanelToten.jsx";
import ErrorPage from '../../../pages/Error/index.jsx';


const Main = () => {
	return (
		<>
		    <div>
		    	<BrowserRouter>
		    		<Switch>
		    			<Route path="/panel-toten/login" exact component={LoginUserStore} />
		    			<PrivateRoutePanelToten path="/panel-toten/logout" exact component={LogoutUserStore} />
		    			<PrivateRoutePanelToten path="/panel-toten" exact component={Home} />
		    			<PrivateRoutePanelToten path="/panel-toten/select-products" exact component={SelectProducts} />
		    			<PrivateRoutePanelToten path="/panel-toten/shopping-cart" exact component={ShoppingCart} />
		    			<PrivateRoutePanelToten path="/panel-toten/payment" exact component={Payment} />
		    			<PrivateRoutePanelToten path="/panel-toten/finish-payment" exact component={FinishPayment} />
		    			<Route path="/error-page/:code" component={ErrorPage} />
						<Route component={ErrorPage} />
		    		</Switch>
		    	</BrowserRouter>
		    </div>
		</>
	);
};

export default Main;