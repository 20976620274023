const types = {
	SET_PRODUCT: '@shoppingcart/SET_PRODUCT',
	REMOVE_PRODUCT: '@shoppingcart/REMOVE_PRODUCT',
	CHANGE_QUANTITY_PRODUCT: '@shoppingcart/CHANGE_QUANTITY_PRODUCT',
	CHANGE_QUANTITY_PACKAGES: '@shoppingcart/CHANGE_QUANTITY_PACKAGES',
	PLUS_QUANTITY_PRODUCT: '@shoppingcart/PLUS_QUANTITY_PRODUCT',
	PLUS_QUANTITY_PACKAGES: '@shoppingcart/PLUS_QUANTITY_PACKAGES',
	DECREASE_QUANTITY_PRODUCT: '@shoppingcart/DECREASE_QUANTITY_PRODUCT',
}

export default types;