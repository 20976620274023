import React from 'react';
import './style.css';
import {Link} from 'react-router-dom';
import logo1 from '../../../assets/images/ss-logo.png';
import Footer from "../../../components/PanelToten/Footer/index.jsx";
import ActionButton from "../../../components/PanelToten/ActionButton/index.jsx";

const HomeToten = () => {

	return (
		<div className="page-home">
			<section className="content-home">
				<img className="logo" src={logo1} alt="" />

				
				<h1 className="msg-welcome">SEJA BEM-VINDO</h1>
				<h2 className="msg-first-smart-store">REALIZE SUAS COMPRAS DE<br/>UMA FORMA PRATICA</h2>

				<Link className="btn-init" to='/panel-toten/shopping-cart'>
					<ActionButton
						type="button"
						text="COMEÇAR"
						background="#4CAF50"
					/>
				</Link>

				<h3 className="msg-click-button">Clique no botão para<br/>começar suas compras de<br/>forma autônoma!</h3>
			</section>

			<Footer />
		</div>
	);
};

export default HomeToten;