import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Table } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";

const View = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('solicitation');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('solicitation');
	const api = apiService.get()
	const [solicitation, setSolicitation] = useState([]);
	const [loading, setLoading] = useState(false);

	const {
	    params: { id }
	} = match;
	
	const view = () => {
		setLoading(true);
	    api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			let data = resp.data.data.solicitation;
			data.store_object = JSON.parse(data?.store_object);
			setSolicitation(data);

			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}
	
	useEffect(() => {
		view()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-view`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Dados da solicitação</h5>

					{ (loading) ? (<Loading />) : (
						<Table striped bordered hover>
							<thead>
								<tr>
									<th style={configDefault?.theme?.prymaryBackground}>Coluna</th>
									<th style={configDefault?.theme?.prymaryBackground}>Valor</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Id</td>
									<td>{solicitation?.id}</td>
								</tr>
								<tr>
									<td>Status</td>
									<td>{solicitation?.status}</td>
								</tr>
								<tr>
									<td>Loja</td>
									<td>{solicitation?.store_object?.name}</td>
								</tr>
								<tr>
									<td>Empresa</td>
									<td>{solicitation?.tenant_id}</td>
								</tr>
								<tr>
									<td>Atendida por:</td>
									<td>{solicitation?.store_closed_id || '-'}</td>
								</tr>

								<tr>
									<td>Preço final:</td>
									<td>{solicitation?.final_price || '-'}</td>
								</tr>

								<tr>
									<td>Preço do frete:</td>
									<td>{solicitation?.final_ship_cost || '-'}</td>
								</tr>

								<tr>
									<td>Subtotal:</td>
									<td>{solicitation?.final_sub_total_price || '-'}</td>
								</tr>
							</tbody>
						</Table>
					)}
				</Can>
				
			</div>
		</>
	);
};

export default View;