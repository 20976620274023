import React, { useState, useEffect } from 'react';
import './style.css';
import 'react-toastify/dist/ReactToastify.css';

const LogoutUserStore = () => {

	const [errors, setErrors] = useState('');/* eslint-disable-line */

	const logout = () => {
		localStorage.removeItem('tokenUserToten');
		localStorage.removeItem('toten');
		window.location = '/panel-toten/login';
	}

	useEffect(() => {
		logout();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			
		</>
	);
};

export default LogoutUserStore;