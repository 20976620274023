import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Table } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";

const View = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('product');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('product');
	const api = apiService.get()
	const [product, setProduct] = useState([]);
	const [loading, setLoading] = useState(false);

	const {
	    params: { id }
	} = match;
	
	const view = () => {
		setLoading(true);
	    api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			setProduct(resp.data.data.product);
			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}

	
	useEffect(() => {
		view()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-view`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Dados do produto</h5>

					{ (loading) ? (<Loading />) : (
						<Table striped bordered hover>
							<thead>
								<tr>
									<th style={configDefault?.theme?.prymaryBackground}>Coluna</th>
									<th style={configDefault?.theme?.prymaryBackground}>Valor</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Id</td>
									<td>{product?.id}</td>
								</tr>
								<tr>
									<td>Nome</td>
									<td>{product?.name}</td>
								</tr>
								<tr>
									<td>Sku</td>
									<td>{product?.sku}</td>
								</tr>
								<tr>
									<td>Código EAN</td>
									<td>{product?.code_ean}</td>
								</tr>
								<tr>
									<td>QRCode</td>
									<td>{product?.qrcode}</td>
								</tr>

								<tr>
									<td>Peso em gramas</td>
									<td>{product?.weight_in_grams}</td>
								</tr>
								<tr>
									<td>Image</td>
									<td>{product?.image !== null ? <img alt="" src={product?.image} /> : 'Nenhuma imagem cadastrada'}</td>
								</tr>
								<tr>
									<td>Marca</td>
									<td>{product?.brand?.name}</td>
								</tr>
							</tbody>
						</Table>
					)}
				</Can>
				
			</div>
		</>
	);
};

export default View;