import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";

const RegisterEdit = props => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('tax');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('tax');

	const { id } = useParams();

	const api = apiService.get();
	const history = useHistory();
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setValue } = useForm();
	
	const onSubmit = data => {
		if(id !== undefined)
			update(data);
		else
			create(data);
	}

	const create = data => {
		setLoading(true);
		
		api.post(`api/${endpoint}`, data)
		.then(resp => {
			showNotify('success', 'Imposto registrado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const update = data => {
		setLoading(true);

		api.put(`api/${endpoint}/${id}`, data)
		.then(resp => {
			showNotify('success', 'Imposto atualizado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors);
			setLoading(false);
			verifyError(error);
		})
	}
	
	const initEndpoint = () => {
		setLoading(true);
		api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			const data = resp.data.data.tax;
			setValue("name", data.name);
			setValue("code", data.code);
			setValue("value_percent", data.value_percent);
			
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	useEffect(() => {
		if(id !== undefined){
			initEndpoint();
		}else{
			document.getElementById('form-register').reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-create`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de registro</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onSubmit)} className="form-register" id="form-register">
						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="name" className="form-label">Nome:<span className="req-span">*</span></label>
								    <input type="text" name="name" className="form-control" id="name" {...register("name")} />
								    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="code" className="form-label">Código:<span className="req-span">*</span></label>
								    <input type="text" name="code" className="form-control" id="code" {...register("code")} />
								    <span className="error-message">{errors?.code ? errors.code[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="value_percent" className="form-label">Valor em porcentagem:<span className="req-span">*</span></label>
								    <input type="number" step="0.00" name="value_percent" className="form-control" id="value_percent" {...register("value_percent")} />
								    <span className="error-message">{errors?.value_percent ? errors.value_percent[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn" style={configDefault?.theme?.prymaryBackground}>{id !== undefined ? 'Salvar' : 'Cadastrar'}</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default RegisterEdit;