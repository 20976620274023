import React, {useState, useEffect, useContext} from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { BiPencil, BiTrash, BiShowAlt, BiPackage } from "react-icons/bi";
import Header from '../Header/index.jsx';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import Pagination from '../../../../components/Pagination/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";


const List = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	const [errors, setErrors] = useState('');
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('store');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('store');
	const [stores, setStores] = useState([]);
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(1);
	const [qtdPages, setQtdPages] = useState(1);
	const api = apiService.get();
	let valuePrev = localStorage.getItem('searchValuePrev');
	const { register, handleSubmit, setValue, getValues } = useForm();

	const {
	    params: { column, value }
	} = match;

	const [budgetsProducts, setBudgetsProducts] = useState([]);
	const [modalBudgetsProductsShow, setModalBudgetsProductsShow] = useState(false);
	const onHideModalBudgetProducts = () => {setModalBudgetsProductsShow(false);}

	const [modalNewBudgetProductsShow, setModalNewBudgetProductsShow] = useState(false);
	const onHideModalNewBudgetProducts = () => {
		setCategoriesSelected([]);
		setModalNewBudgetProductsShow(false);
	}

	const [categories, setCategories] = useState([]);
	const [categoriesSelected, setCategoriesSelected] = useState([]);


	const searchCategory = (e) => {
		if(e.target.value.length > 1){
			api.get(`api/category/by-store/${getValues('store_id')}/search/${e.target.value}`)
			.then(resp => {
				let dataCategories = [];
				resp.data.data?.categories?.forEach(function(category, index){
					if(categoriesSelected.filter(i => i.category_id === category.id).length === 0){
						dataCategories.push(category);
					}
				});
				setCategories(dataCategories);
				//setQtdPages(resp.data.data.pagination.pages);
			})
			.catch(error =>{
				showNotify('error', 'Ops, ocorreu algum erro!');
				setLoading(false);
				verifyError(error);
			})
		}else{
			setCategories([]);
		}
	}

	const listBudgetsProducts = (storeId) => {
		setValue('store_id', storeId);

		api.get(`api/budget-product/by-store/${storeId}`)
		.then(resp => {
			setBudgetsProducts(resp.data.data.budgetsProducts);
			setModalBudgetsProductsShow(true);
			//setQtdPages(resp.data.data.pagination.pages);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	const removeCategorySelected = (categoryId) => {
		setCategoriesSelected(categoriesSelected.filter(item => item.id !== categoryId));
		setCategories([]);
	}

	const changeCategorySelected = (categoryId, name, event) => {
		let categoriesSelectedTemp = categoriesSelected;
		categoriesSelectedTemp.forEach(function(item){
			if(item.category_id == categoryId ){
				if (name == 'percentage_discount')
					item.percentage_discount = parseFloat(event.target.value);
				if (name == 'operation')
					item.operation = event.target.value;
			}
		});
		setCategoriesSelected(categoriesSelectedTemp);

	}

	const addCategorySelected = (category) => {
		let contain = false;
		categoriesSelected.forEach(function(item){
			if(item.category_id === category.id)
				contain = true;
		});
		if(contain == false){
			let categoryProduct = {
				category_id: category?.id,
				category_name: category?.name,
				operation: 'discount',
				percentage_discount: 0,
			};
			setCategoriesSelected([...categoriesSelected, categoryProduct]);
			setCategories([]);
		}else{
			showNotify('error', 'A categoria já foi selecionada!');
		}
		
	}

	const openBudgetProducts = (BudgetProductId) => {
		
		api.get(`api/budget-product/view-budget/${BudgetProductId}`, {responseType: 'blob'})
		.then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data], {type: 'application/pdf'}));
			window.open(url);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors)
			verifyError(error);
		})
	}

	const onSubmitBudgetProduct = () => {

		let data = {
			store_id: getValues('store_id'),
			categories: categoriesSelected
		};
		//setLoading(true);
		
		api.post(`api/budget-product/create-budget`, data)
		.then(resp => {
			showNotify('success', 'Orçamento registrada com sucesso!');
			setModalNewBudgetProductsShow(false);
			setCategoriesSelected([]);
			listBudgetsProducts(getValues('store_id'));
			setLoading(false);
			//history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const list = () => {
		setLoading(true);
		let queryApi = '';
		if(typeof column !== "undefined" && typeof value !== "undefined"){
			if(valuePrev !== value){
				setOffset(1)
				localStorage.setItem('searchValuePrev', value);
			}
			queryApi = `api/${endpoint}/search/${column}/${value}?page=${offset}`;
		}else{
			queryApi = `api/${endpoint}?page=${offset}`;
		}
		
		api.get(queryApi)
		.then(resp => {
			setStores(resp.data.data.stores);
			setQtdPages(resp.data.data.pagination.pages);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}
	
	const deleteItem = (id, e) => {
		setLoading(true);
		api.delete(`api/${endpoint}/${id}`)
		.then(resp => {
			showNotify('success', 'Loja deletada com sucesso!');
			list();
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	useEffect(() => {
		list()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [column, value, offset])


	return (
		<>
			<div className="area-crud">

				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-view`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Lista de lojas</h5>

					{ (loading) ? (<Loading />) : (

						(stores?.length === 0) ? (
		                    <label>Nenhum registro encontrado...</label>
		                ) : (
		                	<div>
			                    <Table striped bordered hover>
									<thead>
										<tr>
											<th style={configDefault?.theme?.prymaryBackground}>Nome</th>
											<th style={configDefault?.theme?.prymaryBackground}>CNPJ</th>
											<th style={configDefault?.theme?.prymaryBackground}>Usuário</th>
											<th style={configDefault?.theme?.prymaryBackground}>Estado</th>
											<th style={configDefault?.theme?.prymaryBackground}>Cidade</th>
											<th style={configDefault?.theme?.prymaryBackground}>Ações</th>
										</tr>
									</thead>
									<tbody>
										{stores?.map((item) => (
											<tr key={item.id}>
												<td>{item.name}</td>
												<td>{item.cnpj}</td>
												<td>{item.user.name}</td>
												<td>{item.address.state}</td>
												<td>{item.address.city}</td>
												<td>
													<Can resource={'budget-product'} authority={`budget-product-view`}>
														<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={() => listBudgetsProducts(item.id)}>
															orçamentos
														</Button>
													</Can>
													<Can resource={roleEndpoint} authority={`${roleEndpoint}-view`}>
														<Link to={`/dashboard/store-product/${item.id}/list`} className="btn btn-dark" style={configDefault?.theme?.prymaryBackground}>
															<BiPackage />
														</Link>
													</Can>
													<Can resource={roleEndpoint} authority={`${roleEndpoint}-view`}>
														<Link to={`/dashboard/${endpoint}/view/${item.id}`} className="btn btn-dark" style={configDefault?.theme?.prymaryBackground}>
															<BiShowAlt />
														</Link>
													</Can>
													<Can resource={roleEndpoint} authority={`${roleEndpoint}-update`}>
														<Link to={`/dashboard/${endpoint}/edit/${item.id}`} className="btn btn-dark" style={configDefault?.theme?.prymaryBackground}>
															<BiPencil />
														</Link>
													</Can>

													<Can resource={roleEndpoint} authority={`${roleEndpoint}-delete`}>
														<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={(e) => {if (window.confirm('Você realmente deseja excluir este registro?')) deleteItem(item.id, e)}}>
															<BiTrash />
														</Button>
													</Can>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
								<Pagination
									qtdPages={qtdPages}
									offset={offset}
									setOffset={setOffset}
								/>

							</div>
		                )
		            )}
	            </Can>
			</div>

			<Modal
                show={modalBudgetsProductsShow}
                fullscreen={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
					
					<h5 className="mb-2">Lista de orçamentos</h5>
					{/*<h6 className="mb-3">Produto: {storeProductSelected?.product?.name}</h6>*/}

					{ (loading) ? (<Loading />) : (

						(budgetsProducts?.length === 0) ? (
		                    <label>Nenhum registro encontrado...</label>
		                ) : (
		                	<div>
			                    <Table striped bordered hover>
									<thead>
										<th>Arquivo</th>
										<th>Data de criação</th>
										<th>Data de expiração</th>
										<th>Ações</th>
									</thead>
									<tbody>
										{budgetsProducts?.map((item, index) => (
											<tr key={item.id}>
												<td>{item?.name_file}</td>
												<td>{item?.created_at}</td>
												<td>{item?.date_expired}</td>
												<td>
													<Button variant="dark" style={configDefault?.theme?.prymaryBackground} onClick={(e) => openBudgetProducts(item.id)}>
														abrir
													</Button>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>

		                )
		            )}
                </Modal.Body>

                <Modal.Footer>
					<Button style={configDefault?.theme?.prymaryBackground} onClick={(e) => setModalNewBudgetProductsShow(true)}>Novo orçamento</Button>
                    <Button style={configDefault?.theme?.prymaryBackground} onClick={onHideModalBudgetProducts}>Fechar</Button>
                </Modal.Footer>
            </Modal>


			<Modal
                show={modalNewBudgetProductsShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
					
					<h5 className="mb-2">Novo orçamento</h5>

					<Row className="mb-3">
						<Col sm="12">
							<Form.Group>
								<label htmlFor="category" className="form-label">Categoria:<span className="req-span">*</span></label>
								<input type="text" className="form-control" id="category" onChange={(e) => searchCategory(e)} />
								<ul class="list-group">
									{categories?.map((item, index) => (
										<li className="list-group-item" key={index}>{item?.name} <Button className="btn-danger" onClick={() => addCategorySelected(item)}>adicionar</Button></li>
									))}
								</ul>
								{/*<span className="error-message">{errors?.name ? errors.name[0] : null}</span>*/}
							</Form.Group>
						</Col>
					</Row>
	
					{categoriesSelected?.map((item, index) => (
						<Row key={index} className="mb-3">

							<Col sm="3">
								<Form.Group>
									<label className="form-label">Categoria:</label>
									<input type="text" className="form-control" disabled value={item?.category_name}/>
									{/*<span className="error-message">{errors?.cnpj ? errors.cnpj[0] : null}</span>*/}
								</Form.Group>
							</Col>
							<Col sm="4">
								<Form.Group>
									<label htmlFor="percentage_discount" className="form-label">Porcentagem do desconto:</label>
									<input type="number" name={`percentage_discount${index}`} className="form-control" id="percentage_discount" onChange={(e) => changeCategorySelected(item.category_id, 'percentage_discount', e)}/>
									{/*<span className="error-message">{errors?.cnpj ? errors.cnpj[0] : null}</span>*/}
								</Form.Group>
							</Col>

							<Col sm="3">
								<label htmlFor="discont" className="form-label">Operação:</label>
								<Form.Select aria-label="Operação" onChange={(e) => changeCategorySelected(item.category_id, 'operation', e)}>
									<option value="discount">Descontar</option>
									<option value="add">Acrescentar</option>
								</Form.Select>
								
							</Col>

							<Col sm="1">
								<label htmlFor="discont" className="form-label">Ações:</label>
								<Button className="btn-danger" onClick={() => removeCategorySelected(item.id)}>remover</Button>
							</Col>
							
						</Row>
					))}
                </Modal.Body>

                <Modal.Footer>
				<Button className="btn" style={configDefault?.theme?.prymaryBackground} onClick={() => onSubmitBudgetProduct()}>Criar</Button>
                    <Button style={configDefault?.theme?.prymaryBackground} onClick={onHideModalNewBudgetProducts}>Fechar</Button>
                </Modal.Footer>
            </Modal>
		</>
	);
};

export default List;