import types from './types'
export function setProduct(data, type){
	let product = {
		dataProduct: data,
		quantity: 1,
		type: type
	};
	return {type: types.SET_PRODUCT, product};
}

export function removeProduct(index){
	return {type: types.REMOVE_PRODUCT, index};
}

export function changeQuantityProduct(index, value){
	let data = {
		index: index,
		value: parseFloat(value)
	};
	return {type: types.CHANGE_QUANTITY_PRODUCT, data};
}

export function changeQuantityPackages(index, value){
	let data = {
		index: index,
		value: parseFloat(value)
	};
	return {type: types.CHANGE_QUANTITY_PACKAGES, data};
}


export function plusQuantityProduct(index){
	return {type: types.PLUS_QUANTITY_PRODUCT, index};
}

export function plusQuantityPackages(index){
	return {type: types.PLUS_QUANTITY_PACKAGES, index};
}

export function decreaseQuantityProduct(index){
	return {type: types.DECREASE_QUANTITY_PRODUCT, index};
}