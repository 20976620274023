import React from 'react';

import { AiOutlineLoading3Quarters } from "react-icons/ai";
import './style.css';

const Loading = props => {
	const LoadingPosition = () => {
		if(props?.position == 'top'){
			return (
				<div className="area-loading-top">
					<label className="icon-loading"><AiOutlineLoading3Quarters /></label>
				</div>
			);
		}
		if(props?.position == 'center'){
			return (
				<div className="area-loading-center">
					<label className="icon-loading"><AiOutlineLoading3Quarters /></label>
				</div>
			);
		}
		return (
			<div className="area-loading-top">
				<label className="icon-loading"><AiOutlineLoading3Quarters /></label>
			</div>
		);
	}

	return (
		<>
			<LoadingPosition />
		</>
	);
};

export default Loading;