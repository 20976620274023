import React from 'react';
import Header from './Header/index.jsx';
import SearchProductByCodeEAN from './SearchProductByCodeEAN/index.jsx';
import SidebarProducts from './SidebarProducts/index.jsx';
import ListProducts from './ListProducts/index.jsx';

import './style.css';

const SelectProducts = () => {
	

	return (
		<div>
			<Header />
			<p className="info-select-products">Selecione os produtos do seu carrinho.</p>
			<SearchProductByCodeEAN></SearchProductByCodeEAN>
			<div className="container mt-5">
				<div className="row">
					{/* <div className="col-4">
						<SidebarProducts />
					</div> */}
					<div className="col-12">
						<ListProducts></ListProducts>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectProducts;