import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Button, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";

const RegisterEdit = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('toten');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('toten');

	const {
	    params: { id }
	} = match;

	const [stores, setStores] = useState([]);
	const [methodsPayment, setMethodsPayment] = useState([]);
	const [methodsPaymentSelected, setMethodsPaymentSelected] = useState([]);
	
	const api = apiService.get();
	const history = useHistory();
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setValue } = useForm();
	
	const onSubmit = data => {
		data.methods_payment = methodsPaymentSelected;
		if(id !== undefined)
			update(data);
		else
			create(data);
	}

	const create = data => {
		setLoading(true);
		
		api.post(`api/${endpoint}`, data)
		.then(resp => {
			showNotify('success', 'PDV registrado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const update = data => {
		setLoading(true);

		api.put(`api/${endpoint}/${id}`, data)
		.then(resp => {
			showNotify('success', 'PDV atualizado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors);
			setLoading(false);
			verifyError(error);
		})
	}

	const initStores = () => {
		api.get('api/store/all')
		.then(resp => {
			setStores(resp.data.data.stores);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const toggleMethodsPayment = (id) => {
		if(methodsPaymentSelected?.find(idMethod => idMethod === id)?.length > 0)
            setMethodsPaymentSelected([...methodsPaymentSelected?.filter(idMethod => idMethod !== id)]);
        else
            setMethodsPaymentSelected([...methodsPaymentSelected, id]);
	}

	const methodIsSelected = (id) => {
		return methodsPaymentSelected?.find(idMethod => idMethod === id)?.length > 0;
	}

	const initMethodsPayment = () => {
		api.get('api/method-payment/all')
		.then(resp => {
			setMethodsPayment(resp.data.data.methodsPayment);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}

	const initEndpoint = () => {
		setLoading(true);
		api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			const data = resp.data.data.toten;
			setValue("name", data.name);
			setValue("status", data.status);
			setValue("store_id", data.store_id);
			setValue("email", data.email);
			data.methods_payment?.forEach(function(item){
				setMethodsPaymentSelected([...methodsPaymentSelected, item?.id]);
			});
			
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}


	useEffect(() => {
		initStores();
		initMethodsPayment();
		if(id !== undefined){
			initEndpoint();
		}else{
			document.getElementById('form-register').reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-create`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de registro</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onSubmit)} className="form-register" id="form-register">
						<Row className="mb-3">
							<Col sm="4">
								<Form.Group>
								    <label htmlFor="name" className="form-label">Nome:<span className="req-span">*</span></label>
								    <input type="text" name="name" className="form-control" id="name" {...register("name")} />
								    <span className="error-message">{errors?.name ? errors.name[0] : null}</span>
								</Form.Group>
							</Col>


							<Col sm="4">
								<Form.Group>
								    <label htmlFor="status" className="form-label">Status:</label>
								    <select className="form-select" name="status" {...register("status")}>
								    	<option value="funcionando">Funcionando</option>
								    	<option value="manutenção">Manutenção</option>
								    	<option value="testando">Testando</option>
							        </select>
								    <span className="error-message">{errors?.status ? errors.status[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="store_id" className="form-label">Loja:</label>
								    <select className="form-select" id="store_id" name="store_id" {...register("store_id")}>
								    	<option value="">Selecionar</option>
							            {stores?.map((store, index) => (
								    		<option value={store.id} key={index}>{store.name}</option>
								    	))}
							        </select>
								    <span className="error-message">{errors?.store_id ? errors.store_id[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="6">
								<Form.Group>
								    <label htmlFor="email" className="form-label">Email:</label>
								    <input type="text" name="email" className="form-control" id="email" {...register("email")} />
								    <span className="error-message">{errors?.email ? errors.email[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="6">
								<Form.Group>
								    <label htmlFor="password" className="form-label">Senha:</label>
								    <input type="password" name="password" className="form-control" id="password" {...register("password")} />
								    <span className="error-message">{errors?.password ? errors.password[0] : null}</span>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="6">
								<Form.Group>
								    <label className="form-label">Metodos de pagamentos ativados:</label>
								    {methodsPayment?.map((item, index) => (
								    	<Form.Check type="checkbox" key={index} label={item?.name} id={index} checked={methodIsSelected(item?.id)} onChange={() => toggleMethodsPayment(item?.id)} />
								    ))}
								</Form.Group>
							</Col>
						</Row>

					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn" style={configDefault?.theme?.prymaryBackground}>{id !== undefined ? 'Salvar' : 'Cadastrar'}</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>
		</>
	);
};

export default RegisterEdit;