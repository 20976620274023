import React, {useState, useEffect, useContext} from 'react';
import {  Button, Col, Row } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";
import './style.css';

const RelationCategories = props => {
	const [configDefault] = useContext(Context);
	const [product, setProduct] = useState({});
	const [categoriesSelected, setCategoriesSelected] = useState([]);
	const [categoriesNotRelacioned, setCategoriesNotRelacioned] = useState([]);
	const [loading, setLoading] = useState(false);
	const api = apiService.get();

	const initProduct = () => {
		setLoading(true);
	    api.get(`api/product/${props.product.id}`)
		.then(resp => {
			setProduct(resp.data.data.product);
			setCategoriesSelected(resp.data.data.product.categories);
			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}

	const listCategories = () => {
		setLoading(true);
		api.get(`api/category/not-relation-to-product/${props.product.id}`)
		.then(resp => {
			setCategoriesNotRelacioned(resp.data.data.categories);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			console.log(error);
			setLoading(false);
			verifyError(error);
		})
	}

	const remove = (category) => {
		setCategoriesNotRelacioned([...categoriesNotRelacioned, category]);
		setCategoriesSelected(categoriesSelected.filter(item => item.id !== category.id));
	}

	const add = (category) => {
		setCategoriesSelected([...categoriesSelected, category]);
		setCategoriesNotRelacioned(categoriesNotRelacioned.filter(item => item.id !== category.id));
	}
	
	const updateRelations = () => {
		setLoading(true);
		let data = {
			categories: []
		};

		categoriesSelected.forEach((item) => {
			data.categories.push(item.id);
		})

		api.post(`api/product/relations-category/${product.id}`, data)
		.then(resp => {
			showNotify('success', 'Relações criadas com sucesso!');
			initProduct();
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	
	useEffect(() => {
		initProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		listCategories();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product])




	return (
		<>
			<Can 
				resource="product"
				authority="product-update"
				otherwiseComponent={<label>Sem acesso!</label>}
			>
				<div className="mb-5 title-modal">
					<h5>Lista de categorias do produto: {product.name}</h5>
					<div>
						<Button onClick={(e) => updateRelations()} variant="primary" style={configDefault?.theme?.prymaryBackground}>Salvar</Button>
						<Button onClick={(e) => initProduct()} variant="danger">Cancelar</Button>
					</div>
				</div>

				{ (loading) ? (<Loading />) : (

                	<Row>
	                	<Col sm="6">
		                	<h5>Categorias não relacionadas</h5>
		                	<ul className="list-group list-group-flush">
								{categoriesNotRelacioned?.map((item) => (
									<li key={item.id} className="list-group-item">{item.name}<Button onClick={(e) => add(item)} variant="success">+</Button></li>
								))}
							</ul>
	                	</Col>

	                	<Col sm="6">
		                	<h5>Categorias do produto</h5>
		                	<ul className="list-group list-group-flush">
		                		{categoriesSelected?.map((item) => (
									<li key={item.id} className="list-group-item">{item.name} <Button onClick={(e) => remove(item)} variant="danger">-</Button></li>
								))}
							</ul>
	                	</Col>
                	</Row>
	            )}
            </Can>
	            
		</>
	);
};

export default RelationCategories;