import React, {useState, useEffect} from 'react';
import './style.css';
import apiService from '../../../services/api.js';
import { showNotify, verifyError } from '../../../services/actionsAppService.jsx';
import { FaFacebookSquare, FaInstagram, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
	const [dataCompany, setDataCompany] = useState({});
	const [dateMonth, setDateMonth] = useState('00');
	const [dateDay, setDateDay] = useState('00');

	const [dateHours, setDateHours] = useState('00');
	const [dateMinutes, setDateMinutes] = useState('00');
	//const [dateSecond, setDateSecond] = useState('00');

	const api = apiService.get();

	const initDataCompany = () => {
		api.get(`api/company/first`)
		.then(resp => {
			const data = resp.data;
			setDataCompany(data);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			//setErrors(error.message)
			verifyError(error);
		})
	}

	useEffect(() => {
		setInterval(function(){
			let d = new Date();	
			setDateMonth((d.getMonth()+1) < 10 ? '0'+(d.getMonth()+1) : d.getMonth()+1);
			setDateDay(d.getDate() < 10 ? '0'+d.getDate() : d.getDate());
			setDateHours(d.getHours() < 10 ? '0'+d.getHours() : d.getHours());
			setDateMinutes(d.getMinutes() < 10 ? '0'+d.getMinutes() : d.getMinutes());
			//setDateSecond(d.getSeconds());
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		initDataCompany();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<footer>
			<div className="social-networks">
				<label><span><FaFacebookSquare /></span> {dataCompany?.facebook}</label>
				<label><span><FaInstagram /></span> {dataCompany?.instagram}</label>
				<label><span><FaWhatsapp /></span> {dataCompany?.whatsapp}</label>
			</div>

			<div className="date-time">
				<label>{dateDay}/{dateMonth}</label>
				<label>{dateHours}:{dateMinutes}</label>
			</div>

			<div className="opening-time">
				<label>ATENDIMENTO</label>
				<div className="week-days">
					<span className={dataCompany?.open_sunday ? 'open-date' : 'close-date'}>D</span>
					<span className={dataCompany?.open_monday ? 'open-date' : 'close-date'}>S</span>
					<span className={dataCompany?.open_tuesday ? 'open-date' : 'close-date'}>T</span>
					<span className={dataCompany?.open_wednesday ? 'open-date' : 'close-date'}>Q</span>
					<span className={dataCompany?.open_thursday ? 'open-date' : 'close-date'}>Q</span>
					<span className={dataCompany?.open_friday ? 'open-date' : 'close-date'}>S</span>
					<span className={dataCompany?.open_saturday ? 'open-date' : 'close-date'}>S</span>
				</div>

				<div className="times">
					<div>
						<label>DAS:</label>
						<label>{dataCompany?.hour_open?.split(':')[0]}:{dataCompany?.hour_open?.split(':')[1]}</label>
					</div>

					<div>
						<label>AS:</label>
						<label>{dataCompany?.hour_close?.split(':')[0]}:{dataCompany?.hour_close?.split(':')[1]}</label>
					</div>
				</div>

			</div>
		</footer>
	);
};

export default Footer;