import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import apiService from '../../../../../services/api.js';
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Loading from '../../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../../services/actionsAppService.jsx';
import Context from "../../../../../Context";
import SelectByCRUD from '../../../../../components/SelectByCRUD/index.jsx';
import RegisterEditProduct from '../../../Product/RegisterEdit/index.jsx';
import RegisterEditTax from '../../../Tax/RegisterEdit/index.jsx';
import { NumericFormat } from 'react-number-format';

const RegisterEdit = props => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('store-product');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('store-product');

    const [productSelected, setProductSelected] = useState({});
	const [modalRegisterProductShow, setModalRegisterProductShow] = useState(false);

	const [taxSelected, setTaxSelected] = useState({});
	const [modalRegisterTaxShow, setModalRegisterTaxShow] = useState(false);

	const onHideModalRegisterProduct = () => {setModalRegisterProductShow(false);}
	const onHideModalRegisterTax = () => {setModalRegisterTaxShow(false);}

	const { id, storeId } = useParams();

	const api = apiService.get();
	const history = useHistory();
	const [errors, setErrors] = useState('');
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setValue, getValues } = useForm();
	
	const onSubmit = data => {
		data.store_id = storeId;
		data.product_id = productSelected?.id;
		data.tax_id = taxSelected?.id;
		data.price = parseFloat(getValues('price').replace(',', '.')) * 100;
		data.promotion_price = parseFloat(getValues('promotion_price').replace(',', '.')) * 100;

		if(id !== undefined)
			update(data);
		else
			create(data);
	}

	const create = data => {
		setLoading(true);
		data.quantity = 0;
		api.post(`api/${endpoint}`, data)
		.then(resp => {
			showNotify('success', 'Produto da loja registrado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/${storeId}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.errors)
			setLoading(false);
			verifyError(error);
		})
	}

	const update = data => {
		setLoading(true);

		api.put(`api/${endpoint}/${id}`, data)
		.then(resp => {
			showNotify('success', 'Produto da loja atualizado com sucesso!');
			setLoading(false);
			history.push(`/dashboard/${endpoint}/${storeId}/list`);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors?.price);
			setLoading(false);
			verifyError(error);
		})
	}

	const initEndpoint = () => {
		setLoading(true);
		api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			const data = resp.data.data.storeProduct;
			setValue("store_id", data.store_id);
			setValue("has_promotion", data?.has_promotion);
			setValue("promotion_price", data?.promotion_price / 100);
			setValue("price", data?.price / 100);
			setProductSelected(data?.product);
			setTaxSelected(data?.tax);

			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error.message)
			verifyError(error);
		})
	}


	useEffect(() => {
		if(id !== undefined){
			initEndpoint();
		}else{
			document.getElementById('form-register').reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return (
		<>
			<div className="area-crud">
				<Header storeId={storeId}></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-create`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Formulário de registro</h5>

					{ (loading) ? (<Loading />) : (null)}

					<Form onSubmit={handleSubmit(onSubmit)} className="form-register" id="form-register">

						<Row className="mb-3">
							
							<Col sm="5">
								<Form.Group>
								    <label className="form-label">Produto:<span className="req-span">*</span></label>
							        <SelectByCRUD
							        	crud="product"
							        	inputSelected={productSelected}
							        	setInputSelected={setProductSelected}
							        	columnsSearch={
							        		[
							        			{columnName: 'name', columnLabel: 'Nome'},
							        			{columnName: 'sku', columnLabel: 'Sku'},
							        			{columnName: 'code_ean', columnLabel: 'Código EAN'},
							        			{columnName: 'status', columnLabel: 'Status'},
							        			{columnName: 'store_id', columnLabel: 'Id da loja'},
							        			{columnName: 'brand_id', columnLabel: 'Id da marca'},
							        		]
							        	}
							        	columnShow="name"
							        	setModalRegisterShow={setModalRegisterProductShow}
							        >
							        </SelectByCRUD>
							        
								    <span className="error-message">{errors?.product_id ? errors.product_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label className="form-label">Imposto:</label>
							        <SelectByCRUD
							        	crud="tax"
							        	inputSelected={taxSelected}
							        	setInputSelected={setTaxSelected}
							        	columnsSearch={
							        		[
							        			{columnName: 'name', columnLabel: 'Nome'},
							        			{columnName: 'code', columnLabel: 'Código'},
							        		]
							        	}
							        	columnShow="name"
							        	setModalRegisterShow={setModalRegisterTaxShow}
							        >
							        </SelectByCRUD>
							        
								    <span className="error-message">{errors?.tax_id ? errors.tax_id[0] : null}</span>
								</Form.Group>
							</Col>

							<Col sm="3">
								<label htmlFor="price" className="form-label">Preço:<span className="req-span">*</span></label>
								<div class="input-group">
									<span class="input-group-text">R$</span>
									<NumericFormat
										value={getValues('price')}
										name="price"
										id="price"
										className="form-control"
									 	decimalScale={2}
									  	decimalSeparator=","
									  	fixedDecimalScale
									  	thousandSeparator="."
									  	onChange={(e) => setValue("price", e.target.value)}
									/>
								</div>
								<span className="error-message">{errors?.price ? errors.price[0] : null}</span>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col sm="4">
								<label htmlFor="promotion_price" className="form-label">Preço da promoção:<span className="req-span">*</span></label>
								<div class="input-group">
									<span class="input-group-text">R$</span>
									<NumericFormat
										value={getValues('promotion_price')}
										name="promotion_price"
										id="promotion_price"
										className="form-control"
									 	decimalScale={2}
									  	decimalSeparator=","
									  	fixedDecimalScale
									  	thousandSeparator="."
									  	onChange={(e) => setValue("promotion_price", e.target.value)}
									/>
								</div>
								<span className="error-message">{errors?.promotion_price ? errors.promotion_price[0] : null}</span>
							</Col>

							<Col sm="4">
								<Form.Group>
								    <label htmlFor="has_promotion" className="form-label">Tem promoção:</label>
								    <select className="form-select" name="has_promotion" {...register("has_promotion")}>
								    	<option value="">Selecionar</option>
								    	<option value="1">Sim</option>
								    	<option value="0">Não</option>
							        </select>
								    <span className="error-message">{errors?.has_promotion ? errors.has_promotion[0] : null}</span>
								</Form.Group>
							</Col>

						</Row>



					    <Row className="mb-3">
					    	<Col>
					    		<Button type="submit" className="btn" style={configDefault?.theme?.prymaryBackground}>{id !== undefined ? 'Salvar' : 'Cadastrar'}</Button>
					    	</Col>
					    </Row>
					    
					</Form>
				</Can>
			</div>

			<Modal
                show={modalRegisterProductShow}
                fullscreen={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                	<RegisterEditProduct
                		redirectToList={false}
                		modalRegisterShow={modalRegisterProductShow}
                		setModalRegisterShow={setModalRegisterProductShow}
                	></RegisterEditProduct>
                </Modal.Body>

                <Modal.Footer>
                    <Button style={configDefault?.theme?.prymaryBackground} onClick={onHideModalRegisterProduct}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={modalRegisterTaxShow}
                fullscreen={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                	<RegisterEditTax
                		redirectToList={false}
                		modalRegisterShow={modalRegisterTaxShow}
                		setModalRegisterShow={setModalRegisterTaxShow}
                	></RegisterEditTax>
                </Modal.Body>

                <Modal.Footer>
                    <Button style={configDefault?.theme?.prymaryBackground} onClick={onHideModalRegisterTax}>Fechar</Button>
                </Modal.Footer>
            </Modal>
		</>
	);
};

export default RegisterEdit;