import React from 'react';
import { Link } from "react-router-dom";
import Logo from "../../../../assets/images/ss-logo.png";
import './style.css';

const Header = () => {
	return (
		<div className="area-header-shopping-cart">
			<Link to="../panel-toten/shopping-cart">VOLTAR</Link>
		
			<h1>PAGAMENTO</h1>

			<img src={Logo} alt="" />
		</div>
	);
};

export default Header;