import React, {useState, useEffect, useContext} from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { showNotify, verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";
import './style.css';

const RelationCategories = props => {
	const [configDefault] = useContext(Context);
	const [category, setCategory] = useState({});
	const [stores, setStores] = useState([]);
	const [idStoreSelected, setIdStoreSelected] = useState('');
	const [productsSelected, setProductsSelected] = useState([]);

	
	const [productsNotRelacioned, setProductsNotRelacioned] = useState([]);
	const [loading, setLoading] = useState(false);
	const api = apiService.get();

	const initCategory = () => {
		setLoading(true);
	    api.get(`api/category/${props.category.id}`)
		.then(resp => {
			setCategory(resp.data.data.category);
			setProductsSelected(resp.data.data.category.products);
			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}

	const initStores = () => {
		setLoading(true);
	    api.get(`api/store`)
		.then(resp => {
			setStores(resp.data.data.stores);
			console.log(resp.data.data.stores);
			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}

	const changeStore = (event) => {
		setIdStoreSelected(event?.target?.value);
		listProducts(event?.target?.value);
	}

	const listProducts = (idStore) => {
		setLoading(true);
		api.get(`api/product/not-relation-to-category/${idStore}/${props.category.id}`)
		.then(resp => {
			setProductsNotRelacioned(resp.data.data.products);
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}

	const remove = (product) => {
		setProductsNotRelacioned([...productsNotRelacioned, product]);
		setProductsSelected(productsSelected.filter(item => item.id !== product.id));
	}

	const add = (product) => {
		setProductsSelected([...productsSelected, product]);
		setProductsNotRelacioned(productsNotRelacioned.filter(item => item.id !== product.id));
	}
	
	const updateRelations = () => {
		setLoading(true);
		let data = {
			products: [],
			store_id: idStoreSelected,
		};

		productsSelected.forEach((item) => {
			data.products.push(item.id);
		})

		api.post(`api/category/relations-product/${category.id}`, data)
		.then(resp => {
			showNotify('success', 'Relações criadas com sucesso!');
			initCategory();
			setLoading(false);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setLoading(false);
			verifyError(error);
		})
	}



	
	useEffect(() => {
		initCategory();
		initStores();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		
		//listProducts();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idStoreSelected])




	return (
		<>
			<Can 
				resource="product"
				authority="product-update"
				otherwiseComponent={<label>Sem acesso!</label>}
			>
				<div className="mb-5 title-modal">
					<h5>Lista de produtos da categoria: {category.name}</h5>
					<div>
						<Button onClick={(e) => updateRelations()} variant="primary" style={configDefault?.theme?.prymaryBackground}>Salvar</Button>
						<Button onClick={(e) => initCategory()} variant="danger">Cancelar</Button>
					</div>
				</div>

				<Row className="mb-4">
	                <Col sm="4">
	                	<Form.Group>
						    <label htmlFor="store_id" className="form-label">Selecionar loja:</label>
						    <select className="form-select" id="store_id" name="store_id" onChange={(event) => changeStore(event)}>
						    	<option value="">Selecionar</option>
					            {stores?.map((store, index) => (
						    		<option value={store.id} key={index}>{store.name}</option>
						    	))}
					        </select>
						</Form.Group>
	                </Col>
	            </Row>


				{ (loading) ? (<Loading />) : (

                	<Row>
	                	<Col sm="6">
		                	<h5>Produtos não relacionados</h5>
		                	<ul className="list-group list-group-flush">
								{productsNotRelacioned?.map((item) => (
									<li key={item.id} className="list-group-item">{item.name}<Button onClick={(e) => add(item)} variant="success">+</Button></li>
								))}
							</ul>
	                	</Col>

	                	<Col sm="6">
		                	<h5>Produtos da categoria</h5>
		                	<ul className="list-group list-group-flush">
		                		{productsSelected?.map((item) => (
									<li key={item.id} className="list-group-item">{item.name} <Button onClick={(e) => remove(item)} variant="danger">-</Button></li>
								))}
							</ul>
	                	</Col>
                	</Row>
	            )}
            </Can>
	            
		</>
	);
};

export default RelationCategories;