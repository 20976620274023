import React, {useState, useEffect, useContext} from 'react';
import { BiSearch } from "react-icons/bi";
import { InputGroup, Modal, FormControl, Button } from 'react-bootstrap';
import Context from "../../Context";
import './style.css';
import apiService from '../../services/api.js';
import { showNotify, verifyError } from '../../services/actionsAppService.jsx';
import { Can } from "react-access-level";
import Pagination from '../Pagination/index.jsx';
import Loading from '../Loading/index.jsx';

const SelectByCRUD = (props) => {
	const [configDefault] = useContext(Context);
	const [modalShow, setModalShow] = useState(false);
	
	const [offset, setOffset] = useState(1);
	const [qtdPages, setQtdPages] = useState(1);
    const [column, setColumn] = useState('');
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const api = apiService.get();
    // eslint-disable-next-line
    const [crud, setCrud] = useState('');
	const [itemsCrud, setItemsCrud] = useState([]);
	const [inputSelected, setInputSelected] = useState({});


	const initItemsCrud = () => {
        setLoading(true);
        let queryApi = '';
		if(column !== '' && value !== ''){
			queryApi = `api/${props.crud}/search/${column}/${value}?page=${offset}`;
		}else{
			queryApi = `api/${props.crud}?page=${offset}`;
		}

        api.get(queryApi)
        .then(resp => {
        	//alteracao da estrutura da requisicao
        	let texto = JSON.stringify(resp.data.data);
        	let inicioDaExtracao = texto.indexOf(":")+1;
        	let fimDaExtracao = texto.indexOf("pagination")-2;
        	texto = texto.slice(inicioDaExtracao, fimDaExtracao).replace(new RegExp(`${props.columnShow}`, 'g'), "name");
        	let data = JSON.parse(texto);

            setItemsCrud(data);
            setQtdPages(resp.data.data.pagination.pages);
            setLoading(false);
        })
        .catch(error =>{
            showNotify('error', 'Ops, ocorreu algum erro!');
            setLoading(false);
            verifyError(error);
        })
    }

    const changeColumn = (ev) => {
        setColumn(ev.target.value);
    }

    const changeValueInputSelect = (ev, item) => {
    	if(item === null)
        	props.setInputSelected({});
        else
        	props.setInputSelected(item);
    }

    const changeValue = (ev) => {
        setValue(ev.target.value.trim());
    }

	const onHideModal = () => {setModalShow(false);}
	

	const onSubmit = (e) => {
		initItemsCrud();
    }

	useEffect(() => {
		setColumn(props.columnsSearch[0].columnName)
		initItemsCrud();
		// eslint-disable-next-line
	}, [props.crud, props.modalRegisterShow, offset])

	useEffect(() => {
		let texto = JSON.stringify(props.inputSelected).replace(new RegExp(`${props.columnShow}`, 'g'), "name");
		setInputSelected(JSON.parse(texto));
		// eslint-disable-next-line
	}, [props.inputSelected])

	return (
		<>
			<div className="select-by-crud">
				<div className="area-value">
					<span>{inputSelected?.name || 'Selecionar'}</span>
					<div>
						<button type="button"><BiSearch onClick={(e) => setModalShow(e, true)} /></button>
					</div>
				</div>
			</div>

			<Modal
                show={modalShow}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                	<Can 
						resource={props.crud}
						authority={`${props.crud}-view`}
						otherwiseComponent={<label>Sem acesso!</label>}
					>
                		<div className="actions-search-select-crud">
	                        <select className="form-select" name="column" onChange={(ev) => changeColumn(ev)}>
	                        	{props.columnsSearch?.map((item) => (
	                            	<option value={item.columnName}>{item.columnLabel}</option>
	                            ))}

	                        </select>
	                        <InputGroup className="input-search">
	                            <FormControl placeholder="Buscar" onChange={(ev) => changeValue(ev)} id="input-search-into-crud" />
	                            <Button onClick={(e) => onSubmit(e)} className="btn btn-dark" type="button" style={configDefault?.theme?.prymaryBackground}><BiSearch /></Button>
	                        </InputGroup>
                        </div>
	                	
	                    <ul className="list-items-select-crud">
							{ (loading) ? (<Loading />) : (

								(itemsCrud?.length === 0) ? (
				                    <label>Nenhum registro encontrado...</label>
				                ) : (
				                	<>
					                	<div className="mt-4 mb-4">
						                    <li>
												<label className="form-check-label mb-3">
											    	Selecionar
												</label>
											</li>
											{itemsCrud?.map((item) => (
												<li key={item.id}>
													<div class="form-check">
														<input className="form-check-input" onChange={(ev) => changeValueInputSelect(ev, item)} type="radio" name="flexRadioDefault" id={item.id} checked={props?.inputSelected?.id === item.id ? true : false} />
														<label className="form-check-label" for={item.id}>
													    	{item.name}
														</label>
													</div>
												</li>
											))}
										</div>

										<Pagination
											qtdPages={qtdPages}
											offset={offset}
											setOffset={setOffset}
										/>
									</>
				                )
				            )}
	                    </ul>

					</Can>
                </Modal.Body>

                <Modal.Footer>
                	<Button style={configDefault?.theme?.prymaryBackground} onClick={(e) => props.setModalRegisterShow(e, true)}>Novo Registro</Button>
                    <Button style={configDefault?.theme?.prymaryBackground} onClick={onHideModal}>Selecionar</Button>
                </Modal.Footer>
            </Modal>

		</>
	);
};

export default SelectByCRUD;