import React, {useState, useEffect} from 'react';
import './style.css';
import logo from '../../../assets/images/ss-logo.png';
import Footer from "../../../components/PanelToten/Footer/index.jsx";
import apiService from '../../../services/api.js';
import { showNotify, verifyError } from '../../../services/actionsAppService.jsx';

const FinishPayment = () => {
	const api = apiService.get();
	const [errors, setErrors] = useState('');

	const locationInitApp = () => {
		setTimeout(function(){
			window.location = '/panel-toten/';
		}, 20000);
	}

	useEffect(() => {
		locationInitApp();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const viewReceipt = () => {
		//ainda precisa passar o id da compra na rota
		api.get(`api/receipt/view-receipt/`, {responseType: 'blob'})
		.then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data], {type: 'application/pdf'}));
			window.open(url);
		})
		.catch(error =>{
			showNotify('error', 'Ops, ocorreu algum erro!');
			setErrors(error?.response?.data?.data?.errors)
			verifyError(error);
		})
	}

	return (
		<div className="page-finish-payment">
			<div className="container-page-finish-payment">
				<img src={logo} className="logo-page-finish" alt="" />

				<h1 className="title-finish">FEITO!</h1>
				<p className="msg-purchase-successfully">Viu como foi simples?</p>
				<label className="msg-obs">Agora é importante que você:</label>

				<ul className="step-by-step">
					<li><span>1º</span> Retire seu comprovante</li>
					<li><span>2º</span> Verifique os produtos</li>
					<li><span>3º</span> Relate se ouver algum problema</li>
					<li><span>4º</span> Visualize o cupom gerado <label className="btn-view-receipt" onClick={(e) => viewReceipt()}>clicando aqui</label></li>
				</ul>

				<h1 className="msg-thanks">VOLTE SEMPRE!</h1>
			</div>

			<Footer />
		</div>
	);
};

export default FinishPayment;