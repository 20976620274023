import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Table } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";

const View = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	const api = apiService.get()
	const [user, setUser] = useState([]);
	const [loading, setLoading] = useState(false);

	const {
	    params: { id }
	} = match;
	
	const viewUser = () => {
		setLoading(true);
	    api.get(`api/user/${id}`)
		.then(resp => {
			setUser(resp.data.data.user);
			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}

	
	useEffect(() => {
		viewUser()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource="user"
					authority="user-view"
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Dados do usuário</h5>

					{ (loading) ? (<Loading />) : (
						<Table striped bordered hover>
							<thead>
								<tr>
									<th style={configDefault?.theme?.prymaryBackground}>Coluna</th>
									<th style={configDefault?.theme?.prymaryBackground}>Valor</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Id</td>
									<td>{user?.id}</td>
								</tr>
								<tr>
									<td>Nome</td>
									<td>{user?.name}</td>
								</tr>
								<tr>
									<td>Email</td>
									<td>{user?.email}</td>
								</tr>
								<tr>
									<td>CPF</td>
									<td>{user?.cpf}</td>
								</tr>
								<tr>
									<td>Telefone</td>
									<td>{user?.phone_number}</td>
								</tr>
							</tbody>
						</Table>
					)}
				</Can>
				
			</div>
		</>
	);
};

export default View;