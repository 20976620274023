import React, {useState, useEffect, useContext} from 'react';
import Header from '../Header/index.jsx';
import { Table } from 'react-bootstrap';
import apiService from '../../../../services/api.js';
import Loading from '../../../../components/Loading/index.jsx';
import { Can } from "react-access-level";
import { verifyError } from '../../../../services/actionsAppService.jsx';
import Context from "../../../../Context";

const View = ({ match, location }) => {
	const [configDefault] = useContext(Context);
	// eslint-disable-next-line
	const [endpoint, setEndpoint] = useState('store');
	// eslint-disable-next-line
    const [roleEndpoint, setRoleEndpoint] = useState('store');
	const api = apiService.get()
	const [store, setStore] = useState([]);
	const [loading, setLoading] = useState(false);

	const {
	    params: { id }
	} = match;
	
	const view = () => {
		setLoading(true);
	    api.get(`api/${endpoint}/${id}`)
		.then(resp => {
			setStore(resp.data.data.store);
			setLoading(false);
		})
		.catch(error =>{
			setLoading(false);
			verifyError(error);
		})
	}

	
	useEffect(() => {
		view()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	return (
		<>
			<div className="area-crud">
				<Header></Header>

				<Can 
					resource={roleEndpoint}
					authority={`${roleEndpoint}-view`}
					otherwiseComponent={<label>Sem acesso!</label>}
				>
					<h5 className="mb-3">Dados da loja</h5>

					{ (loading) ? (<Loading />) : (
						<Table striped bordered hover>
							<thead>
								<tr>
									<th style={configDefault?.theme?.prymaryBackground}>Coluna</th>
									<th style={configDefault?.theme?.prymaryBackground}>Valor</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Id</td>
									<td>{store?.id}</td>
								</tr>
								<tr>
									<td>Nome</td>
									<td>{store?.name}</td>
								</tr>
								<tr>
									<td>CNPJ</td>
									<td>{store?.cnpj}</td>
								</tr>
								<tr>
									<td>Usuário</td>
									<td>{store?.user?.name}</td>
								</tr>
								<tr>
									<td>CEP</td>
									<td>{store?.address?.zipcode}</td>
								</tr>
								<tr>
									<td>Estado</td>
									<td>{store?.address?.state}</td>
								</tr>
								<tr>
									<td>Cidade</td>
									<td>{store?.address?.city}</td>
								</tr>
								<tr>
									<td>Bairro</td>
									<td>{store?.address?.neighborhood}</td>
								</tr>
								<tr>
									<td>Rua</td>
									<td>{store?.address?.street}</td>
								</tr>
								<tr>
									<td>Número</td>
									<td>{store?.address?.number}</td>
								</tr>
								<tr>
									<td>Complemento</td>
									<td>{store?.address?.complement}</td>
								</tr>
							</tbody>
						</Table>
					)}
				</Can>
				
			</div>
		</>
	);
};

export default View;